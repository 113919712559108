import React from "react";
import Compare from "./compare";
import crowSecond from "../../../images/homeHeroSection/crowSecond.jpg";
import crowThird from "../../../images/homeHeroSection/crowThird.webp";

function CompareDemo() {
  return (
    <div
      className="herosection-right-compare w-full max-w-[600px] mx-auto rounded-3xl 
        border-1 border-orange-900 
        dark:bg-neutral-900 dark:border-orange-900"
    >
      <Compare
        firstImage={crowThird}
        secondImage={crowSecond}
        firstImageClassName="object-cover w-full h-full"
        secondImageClassname="object-cover object-left-bottom w-full h-full"
        className="w-full aspect-square rounded-3xl"
        slideMode="hover"
        initialSliderPercentage={70} // posunuto více doprava

      />
    </div>
  );
}

export default CompareDemo;
