import React, { useEffect, useState } from "react";
import LazyLoad from "react-lazyload";
import "../components/styles/gallery.css";
import hoverIcon from "../images/svg/magnifying-glass.svg";
import { useLocation } from "react-router-dom";
import heropic from "../images/gallery/heropic.webp";
import HelmetComponent from "../components/HelmetComponent";
import { useTranslation } from "react-i18next";

function importAll(r) {
  return r.keys().map(r);
}

// Importujeme všechny .webp obrázky z adresáře gallery
const images = importAll(
  require.context("../images/gallery", false, /\.webp$/)
);

export function Gallery() {
  const { t } = useTranslation();
  const myRef = React.useRef(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const location = useLocation();

  const handleClick = () => {
    myRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  // Sledujeme pozici myši pro interaktivní pozadí
  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => window.removeEventListener("mousemove", handleMouseMove);
  }, []);

  useEffect(() => {
    if (location.state?.scrollToAbout) {
      const aboutSection = document.getElementById("about-section");
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <HelmetComponent
        title={"Galerie | Balloon - Light - Praha s.r.o."}
        description={
          "Nahlédněte do naší fotogalerie a objevte zákulisí hollywoodských i českých filmů, akcí či festivalů, kde naše osvětlení vytváří jedinečnou atmosféru."
        }
        keywords={
          "fotogalerie, zákulisí realizace, osvětlení, atmosféra, filmové projekty, Balloon - Light, heliové balony"
        }
      />
      <div className="text-white mx-[10%] mt-[5%] flex-1 gallery-wrapper">
        <div className="heroSection flex flex-col lg:flex-row">
          <div className="absolute inset-0 bg-dots z-0">
            <DotsBackground mousePosition={mousePosition} />
          </div>
          <div className="flex-1 flex flex-col">
            <div className="gallery-hero flex-col flex lg:flex-row">
              <div className="hero-left xl:h-[85%] flex items-center">
                <div className="gallery-left-text">
                  <h1 className="hero-h1">
                    {t("GalleryHero1")}{" "}
                    <span className="orange-text">{t("GalleryHero2")}</span>
                  </h1>
                  <button
                    onClick={handleClick}
                    className="relative inline-flex items-center mt-[5%] justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium rounded-3xl group bg-transparent border-2 border-orange-900 hover-border-color-change"
                  >
                    <span className="galleryHeroButton relative px-5 py-2.5 text-3xl transition-all ease-in duration-75 rounded-3xl text-white">
                      {t("GalleryHeroButton")}
                    </span>
                  </button>
                </div>
              </div>
              <div className="hero-right">
                <img className="hero-img rounded-2xl" src={heropic} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="gallery-container xl:mt-[3%] mt-[-10%]" ref={myRef}>
        <div className="gallery-grid-container">
          {images.slice(1).map((src, index) => (
            <div
              key={index}
              className="gallery-grid-item rounded-md"
              onClick={() => openModal(src)}
            >
              <LazyLoad
                height={200}
                offset={500}
                once
                placeholder={
                  <div style={{ height: 200, backgroundColor: "#ccc" }}></div>
                }
              >
                <img
                  className="defaultImage"
                  src={src}
                  alt={`Obrázek ${index + 1}`}
                />
              </LazyLoad>
              <div className="hover-icon">
                <img src={hoverIcon} alt="Zvětšit" />
              </div>
            </div>
          ))}
        </div>
      </div>

      {isModalOpen && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img
              src={currentImage}
              alt="Full size"
              className="modal-image"
              style={{
                maxWidth: "90%",
                maxHeight: "90vh",
                objectFit: "contain",
              }}
            />
          </div>
          <div className="modal-close" onClick={closeModal}>
            &times;
          </div>
          <div className="modal-caption">{t("GalleryModal")}</div>
        </div>
      )}
    </>
  );
}

function DotsBackground({ mousePosition }) {
  const [dots, setDots] = useState([]);

  useEffect(() => {
    const rows = Math.ceil(window.innerHeight / 40);
    const cols = Math.ceil(window.innerWidth / 40);
    const newDots = [];

    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < cols; j++) {
        newDots.push({ x: j * 50, y: i * 50 });
      }
    }
    setDots(newDots);
  }, []);

  return (
    <div className="bg-dots">
      {dots.map((dot, index) => (
        <div
          key={index}
          className="dot"
          style={{
            left: dot.x,
            top: dot.y,
            backgroundColor:
              Math.sqrt(
                (mousePosition.x - dot.x) ** 2 + (mousePosition.y - dot.y) ** 2
              ) < 100
                ? "orange"
                : "#555",
          }}
        />
      ))}
    </div>
  );
}

export default Gallery;
