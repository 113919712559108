import { products } from "../components/productList.js";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HelmetComponent from "../components/HelmetComponent.jsx";

export default function Products() {
  const { t } = useTranslation();
  return (
    <>
      <HelmetComponent
        title={"Produkty | Balloon - Light - Praha s.r.o."}
        description={
          "Prohlédněte si naši širokou nabídku: podvodní LED světla, No Gravity Light, Pad Light, Flat Light, elipsy, tuby, koule i kostky pro různé projekty."
        }
        keywords={
          "osvětlení, podvodní LED světla, No Gravity Light, Pad Light, Flat Light, elipsy, tuby, kostky, koule, heliové balony, filmové osvětlení, pronájem, prodej"
        }
      />
      <div className="productWrapper min-h-screen p-5  mt-[10%] xl:mx-[10%] mx-[5%]">
        <div className="productCols grid grid-cols-3 gap-4">
          {products.map((product) => (
            <div
              key={product.id}
              className="flex flex-col border p-4 rounded-lg shadow-lg bg-[#101a2e]"
              style={{ border: "1px solid #101a2e" }}
            >
              <div className="flex-1 flex flex-col">
                <img
                  src={product.image}
                  alt={t(`products.${product.translationKey}.name`)}
                  className="w-full h-72 object-fill rounded-md mb-4"
                />
                <h1 className="font-semibold text-2xl">
                  {t(`products.${product.translationKey}.name`)}
                </h1>
                <p className="flex-1 text-lg tracking-wide">
                  {t(`products.${product.translationKey}.miniDescription`)}
                </p>{" "}
              </div>
              <Link to={`/product/${product.slug}`} key={product.slug}>
                <button className="bg-blue-500 font-semibold text-lg tracking-wide text-white px-4 py-2 mt-2 rounded-md w-full">
                  {t("ProductCardButton")}
                </button>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
