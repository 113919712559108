import i18n from "i18next";
import { initReactI18next } from "react-i18next";

i18n.use(initReactI18next).init({
  resources: {
    cs: {
      translation: {
        Home: "Domů",
        Before: "Před",
        After: "Po",
        AboutUs: "O nás",
        Products: "Produkty",
        Projects: "Projekty",
        Gallery: "Galerie",
        GalleryModal: "Klikněte kamkoliv pro zavření",
        Contact: "Kontakt",
        ContactInfo: "Kontaktní informace",
        ContactFormTitle: "Kontaktní formulář",
        HomeHero: "Heliové a vzduchem plněné osvětlovací balony",
        HomeAdvantages: "Naše přednosti",
        HomeHeroDes1:
          "Jsme",
        HomeHeroDes2: "Balloon-light-Praha s.r.o.",
        HomeHeroDes3:
          "a od roku 1999 proměňujeme projekty ve světelný zážitek. Naše inovativní materiály a zkušený tým rozsvítí filmy, reklamní kampaně i eventy. Navíc vám nyní nabízíme moderní LED RGBWW řešení, která posouvají kvalitu našeho osvětlení na novou úroveň. Vaše projekty jsou naší vášní – společně vytvoříme něco jedinečného.",
        HomeHeroButton: "Kontaktujte nás",
        HomeHeroButton2: "Dozvědět se více",
        AboutUsTitle: "O nás",
        AboutUsDes1: "Firma Balloon - light - Praha",
        AboutUsDes2:
          "s.r.o. nabízí heliem a vzduchem plněné balony k osvětlení interiérů či exteriérů. Při použití heliových balónů zůstává hladina světla stejná - výsledkem je tudíž měkké světlo téměř bez stínů. Tím je tento produkt využíván především ve filmovém průmyslu. Snažíme se neustále posouvat naše produkty novým trendům a potřebám zákazníků.",
        AboutUsProjects: "Projektů jsme pomohli zrealizovat",
        AboutUsLocations: "Různých lokací",
        AboutUsYears: "Let v oboru",
        AboutUsYears2: "Let zkušeností",
        AboutUsProducts: "Produktů v nabídce",
        WhyUsTitle: "Proč si vybrat nás?",
        WhyUsDes: "Spojujeme špičkovou kvalitu, profesionální přístup a moderní design, abychom vytvořili dokonalé řešení pro jakoukoli událost. S námi máte jistotu, že vaše akce bude opravdu zářit.",
        WhyUsCards: {
          europeanMarketLeaders: {
            title: "Lídři na evropském trhu",
            subtitle: "Jsme největší společností v Evropě specializující se na osvětlovací balóny. Naše zkušenosti a rozsah služeb jsou bezkonkurenční.",
          },
          bestMaterials: {
            title: "Nejlepší materiály na trhu",
            subtitle: "Používáme pouze prvotřídní materiály, které zaručují dlouhou životnost, odolnost a bezpečnost každého balónu.",
          },
          professionalTeam: {
            title: "Profesionální tým odborníků",
            subtitle: "Naši specialisté jsou zkušení profesionálové připraveni zajistit hladký průběh vaší akce od návrhu po realizaci.",
          },
          hollywoodPartner: {
            title: "Partner hollywoodských produkcí",
            subtitle: "Naše balóny osvětlují scény ve světově proslulých filmech a reklamách.",
          },
        },

        HomeProducts: "Naše produkty",
        HomeProductsButton: "Všechny produkty",
        ProductsProductsButton: "Další produkty",
        HomeProjects: "Realizované projekty",
        HomeProjectsButton: "Všechny projekty",
        HomeGallery: "Galerie",
        HomeGalleryButton: "Celá galerie",
        FooterFormName: "Jméno",
        FooterFormEmail: "Email",
        FooterFormTopic: "Předmět",
        FooterFormMessage: "Zpráva",
        FooterFormButton: "Odeslat",
        FooterPhone: "Telefonní číslo",
        EmailResponse: "Děkujeme. Toto je potvrzení, že jsme obdrželi váš e-mail.",
        ProjectsHero: "Koukněte se na projekty,",
        ProjectsHero2: "kterých jsme byli součástí.",
        ProjectsHeroSub:
          "Více než 2900 projektů na více než 2400 lokacích. Jsme hrdí na to, že jsme spolupracovali s předními hráči v oblasti digitálního streamování, včetně Netflixu, Disney, Amazon Prime a jiné.",
        ProjectsHeroButton: "Portfolio",
        GalleryHero1: "Osvětlení, které dotváří příběh, ",
        GalleryHero2:
          "naše balónová světla z každé scény dělají mistrovské dílo.",
        GalleryHeroButton: "Galerie",
        ProductCardButton: "Detailní popis",
        products: {
          podvodniLedSvetla: {
            name: "PODVODNÍ SVĚTLA",
            miniDescription: "Underwater - LED RGBWW - Light",
            description:
              "Naše podvodní světla jsou využívaná především ve filmovém a TV průmyslu: Blade Runner 2049 / Mission: Impossible 6 / Submergence / Kingsman: The golden circle",
            categories: {
              Model: {
                model: "LED RGBWW - 4-foot Tube",
                size: "	1246mm x 56mm",
                power: "více než 140W",
              },
              Info: {
                0: "LED RGBWW podvodní světlo",
                1: "140 Watts @ 2700K (všude)",
                2: "140 Watts @ 6500K (všude)",
                3: "140 Watts @ rgb 4100K (pod vodou)",
                4: "48V DC",
                5: "variabilní montáž podél celé délky světla",
                6: "nastavení frekvence přes DMX",
                7: "IP-Rating: IP68 & IP69K",
                8: "bezvzduchová technologie - vše naplněno polyuretanem",
              },
            },
          },
          noGravity: {
            name: "NO GRAVITY LIGHT",
            miniDescription: "Možnosti: LED RGBWW",
            description:
              "Naše No Gravity světla jsou využívaná především ve filmovém a TV průmyslu. Jejich výhodou jsou nízká výška, nízký objem helia, snadná manipulace i ovládání. Lze plnit i vzduchem.",
            categories: {
              Info: {
                0: "Teplota 2700K až 6500K",
                1: "Zdroj (ballast) 220V",
                2: "Variabilní umístění.",
                3: "Nastavení frekvence přes vlastní DMX ovládání přímo v ballastu.",
                4: "Další možnost ovládání přes DMX pult i vzdáleně přes Wi-fi.",
              },

            },
          },
          suncut: {
            name: "SUNCUT balóny",
            miniDescription:
              "Možnosti: Stínění",
            description:
              "Naše stínící balony efektivně blokují přímé sluneční paprsky a umožňují precizní kontrolu světla",
            categories: {
              Info: {
                0: "Teplota 2700K až 6500K",
                1: "Zdroj (ballast) 220V",
                2: "Variabilní umístění.",
                3: "Nastavení frekvence přes vlastní DMX ovládání přímo v ballastu.",
                4: "Další možnost ovládání přes DMX pult i vzdáleně přes Wi-fi.",
              },
            },
          },
          flatLight: {
            name: "FLAT LIGHT (Stativové)",
            miniDescription: "Možnosti: LED RGBWW",
            description: "Naše Flat Light jsou lehká a úzká, díky unikátní skládací konstrukci nenáročná pro přepravu. Lze je umístit na stativ, případně zavěsit na lanech. Jsou oblíbená pro svou snadnou manipulaci i ovládání. Flaty lze sestavit do libovolných rozměrů – vždy s délkou i šířkou, které jsou násobkem 4 (např. 4+4+4+… × 4+4+4+…). Flaty do rozměru 12x12 lze umístit do stativu.",
            categories: {
              Info: {
                0: "Teplota 2000K až 10000K",
                1: "Doplňky: Eggcreate",
                2: "Nastavení frekvence přes vlastní DMX ovládání přímo v ballastu.",
                3: "Další možnost ovládání přes DMX pult i vzdáleně přes Wi-fi.",
                5: "Flaty do rozměru 12x12 lze umístit do stativu.",
                6: "Flaty lze sestavit do libovolných rozměrů – vždy s délkou i šířkou, které jsou násobkem 4 (např. 4+4+4+… × 4+4+4+…).",

              },
            },
          },
          elipsy: {
            name: "STATIVOVÉ BALÓNY",
            miniDescription: "Možnosti: LED RGBWW - Tungsten - HMI - Mix light",
            description:"Naše stativové balóny se plní vzduchem, a jsou proto velmi lehké. Lze je snadno zavěsit bez složitých riggingových systémů jako u běžných světel či panelů. Jsou oblíbené pro nízkou výšku, snadnou manipulaci a jednoduché ovládání. Díky skladnosti jsou nenáročné na přepravu.",
            categories: {
              Info: {
                0: "Teplota 2700K až 10000K",
                1: "Zdroj (ballast) 220/380V",
                2: "Variabilní umístění.",
                3: "Nastavení frekvence přes vlastní DMX ovládání přímo v ballastu.",
                4: "Další možnost ovládání přes DMX pult i vzdáleně přes Wi-fi.",
                5: "¼ CTO,½ CTO,Full CTO",
                6: "Single Nett, Double Nett",
                7: "Plus Green, Minus Green",
                8: "Pro všechny Gaffair Balloons-1,2kw,2,4kw,4kw HMI"
              },
            },
          },
          tuby: {
            name: "TUBY",
            miniDescription:
              "Možnosti: LED RGBWW - Tungsten - HMI - Mix",
            description: "Tuba je osvětlovací balón, který lze plnit heliem nebo vzduchem. Při plnění heliem se balón vznáší a může být umístěn volně v prostoru. Pokud je naplněná vzduchem, je nutné jej zavěsit nebo vyzdvihnout pomocí jeřábu, ke kterému se bezpečně upevní.",
          },
          kostky: {
            name: "KOSTKY",
            miniDescription: "Možnosti: LED RGBWW - Tungsten - HMI - Mix",
            description: "Kostka je osvětlovací balón, který lze plnit heliem nebo vzduchem. Při plnění heliem se balón vznáší a může být umístěn volně v prostoru. Pokud je naplněná vzduchem, je nutné jej zavěsit nebo vyzdvihnout pomocí jeřábu, ke kterému se bezpečně upevní.",
          },
          matrace: {
            name: "MATRACE",
            miniDescription: "Možnosti: LED RGBWW - Tungsten - HMI - Mix",
            description: "Matrace je osvětlovací balón, který lze plnit heliem nebo vzduchem. Při plnění heliem se balón vznáší a může být umístěn volně v prostoru. Pokud je naplněná vzduchem, je nutné jej zavěsit nebo vyzdvihnout pomocí jeřábu, ke kterému se bezpečně upevní.",
          },
          koule: {
            name: "KOULE",
            miniDescription: "Možnosti: LED RGBWW - Tungsten - HMI - Mix",
            description: "Koule je osvětlovací balón, který lze plnit heliem nebo vzduchem. Při plnění heliem se balón vznáší a může být umístěn volně v prostoru. Pokud je naplněná vzduchem, je nutné jej zavěsit nebo vyzdvihnout pomocí jeřábu, ke kterému se bezpečně upevní.",
          },
        },
      },
    },
    en: {
      translation: {
        Home: "Home",
        Before: "Before",
        After: "After",
        AboutUs: "About Us",
        Products: "Products",
        Projects: "Projects",
        Gallery: "Gallery",
        GalleryModal: "Click anywhere to close",
        Contact: "Contact",
        ContactInfo: "Contact information",
        ContactFormTitle: "Contact form",
        HomeHero: "Helium and air-filled lighting balloons",
        HomeAdvantages: "Our advantages",
        HomeHeroDes1:
          "First-class, innovative lighting material, assembly of an unparalleled individual offer, experienced staff - these are the advantages of the company",
        HomeHeroDes2: "Balloon - light - Prague",
        HomeHeroDes3:
          "s.r.o., which has been passed down since 1999. We now also offer our products in LED RGBWW versions.",
        HomeHeroButton: "Contact us",
        HomeHeroButton2: "Learn more",
        AboutUsTitle: "About us",
        AboutUsDes1: "The company Balloon - light - Prague",
        AboutUsDes2: "s.r.o. offers helium and air-filled balloons for interior or exterior lighting. When using helium balloons, the light level remains the same - the result is soft light with almost no shadows. This is why this product is used primarily in the film industry. We are constantly trying to advance our products to new trends and customer needs.",
        AboutUsProjects: "Projects we have helped to realize",
        AboutUsLocations: "Different locations",
        AboutUsYears: "Years in the industry",
        AboutUsYears2: "Years of experience",
        AboutUsProducts: "Products in the offer",

        WhyUsTitle: "Why should you choose us?",
        WhyUsDes: "We combine top quality, professionalism and modern design to create the perfect solution for any event. With us, you can be sure that your event will truly shine.",
        WhyUsCards: {
          europeanMarketLeaders: {
            title: "European market leaders",
            subtitle: "We are the largest company in Europe specialising in lighting balloons. Our experience and range of services are unrivalled.",
          },
          bestMaterials: {
            title: "The best materials on the market",
            subtitle: "We use only first-class materials that guarantee the longevity, durability and safety of each balloon.",
          },
          professionalTeam: {
            title: "Professional team of experts",
            subtitle: "Our specialists are experienced professionals ready to ensure the smooth running of your event from design to implementation.",
          },
          hollywoodPartner: {
            title: "Partner of Hollywood productions",
            subtitle: "Our balloons light scenes in world famous films and commercials.",
          },
        },

        HomeProducts: "Our products",
        HomeProductsButton: "All products",
        ProductsProductsButton: "More products",
        HomeProjects: "Realized projects",
        HomeProjectsButton: "All projects",
        HomeGallery: "Gallery",
        HomeGalleryButton: "Whole gallery",
        FooterFormName: "Name",
        FooterFormEmail: "Email",
        FooterFormTopic: "Topic",
        FooterFormMessage: "Message",
        FooterFormButton: "Send",
        FooterPhone: "Phone number",
        EmailResponse: "Děkujeme. Toto je potvrzení, že jsme obdrželi váš e-mail.",
        ProjectsHero: "Check out the projects,",
        ProjectsHero2: "we've been a part of.",
        ProjectsHeroSub:
          "More than 2900 projects at over 2400 locations. We are proud to have worked with leading players in the digital streaming space, including Netflix, Disney, Amazon Prime and others.",
        ProjectsHeroButton: "Portfolio",
        GalleryHero1: "Lighting that completes the story,",
        GalleryHero2: "our balloon lights make every scene a masterpiece.",
        GalleryHeroButton: "Gallery",
        ProductCardButton: "Detail description",
        UnderWaterName: "Underwater LED RGBWW Lights",
        UnderWaterMiniDes: "Underwater - LED RGBWW - Light",
        UnderWaterDes:
          "Our underwater lights are mainly used in the film and TV industry: Blade Runner 2049 / Mission: Impossible 6 / Submergence / Kingsman: The golden circle",
        UnderWaterSpec1: "BiColor underwater light",
        UnderWaterSpec2: "70 Watts @ 2700K (everywhere)",
        UnderWaterSpec3: "70 Watts @ 6500K (everywhere)",
        UnderWaterSpec4: "140 Watts @ ca. 4100K (underwater only)",
        UnderWaterSpec6:
          "variable mounting along the entire length of the light",
        UnderWaterSpec7: "15KHz-16KHz frequency setting via DMX",
        UnderWaterSpec9: "airless technology - all filled with polyurethane",
        UnderWaterSpec10:
          "maximum cable length/tested at 30 meters in the ocean (in 2015 TV show White Sharks in SA)",
        products: {
          podvodniLedSvetla: {
            name: "Underwater light",
            miniDescription: "Underwater - LED RGBWW - Light",
            description:
              "Our underwater lights are mainly used in the film and TV industry: Blade Runner 2049 / Mission: Impossible 6 / Submergence / Kingsman: The golden circle",
            categories: {
              Model: {
                model: "LED RGBWW - 4-foot Tube",
                size: "	1246mm x 56mm",
                power: "more than 140W",
              },
              Info: {
                0: "LED RBGWW underwater light",
                1: "140 Watts @ 2700K (everywhere)",
                2: "140 Watts @ 6500K (everywhere)",
                3: "140 Watts @ rgb 4100K (underwater only)",
                4: "48V DC",
                5: "variable mounting along the entire length of the light",
                6: "frequency setting via DMX",
                7: "IP-Rating: IP68 & IP69K",
                8: "airless technology - all filled with polyurethane",
              },
            },
          },
          noGravity: {
            name: "No Gravity Light",
            miniDescription: "Options: LED RGBWW",
            description:
              "Our No Gravity lights are mainly used in the film and TV industry. Their advantage is low height, low volume of helium, easy manipulation and control. Can be filled with air.",
            categories: {
              Info: {
                0: "Temperature 2700K to 6500K",
                1: "Source (ballast) 220V",
                2: "Variable placement.",
                3: "Frequency setting via own DMX control directly in the ballast.",
                4: "Additional control via DMX console and remotely via Wi-fi.",
              },
            },
          },
          suncut: {
            name: "SUNCUT balloons",
            miniDescription: "Options: Light shielding",
            description: "Our shading balloons effectively block direct sunlight and allow precise light control.",
            categories: {
              Info: {
                0: "Temperature 2700K to 6500K",
                1: "Source (ballast) 220V",
                2: "Variable placement.",
                3: "Frequency setting via own DMX control directly in the ballast.",
                4: "Additional control via DMX console and remotely via Wi-fi.",
              },
            },
          },
          flatLight: {
            name: "FLAT LIGHT (Stative)",
            miniDescription: "Options: LED RGBWW",
            description:"Our Flat Lights are lightweight and slim, and thanks to their unique foldable design, they are easy to transport. They can be mounted on a tripod or suspended with ropes. They are popular for their easy handling and control. Flats can be built in any size - always with length and width that are multiples of 4 (e.g. 4+4+4+...×4+4+4+...). Flats up to 12x12 can be placed in a stative.",            categories: {
              Info: {
                0: "Temperature 2000K to 10000K",
                1: "Accessories: Eggcreate",
                2: "Frequency setting via own DMX control directly in the ballast.",
                3: "Additional control via DMX console and remotely via Wi-fi.",
                5: "Flats up to 12x12 can be placed in a stative.",
                6: "Flats can be built in any size - always with length and width that are multiples of 4 (e.g. 4+4+4+...×4+4+4+...).",
              },
            },
          },
          elipsy: {
            name: "Tripod balloons",
            miniDescription: "Options: LED RGBWW - Tungsten - HMI - Mix light",
            description: "Our Tripod balloons are filled with air, making them extremely lightweight. They can be easily suspended without complex rigging systems like those required for traditional lights or panels. They are popular for their low profile, easy handling, and simple control. Their compact design makes transport effortless.",
            categories: {
              Info: {
                0: "Temperature 2700K to 10000K",
                1: "Source (ballast) 220/380V",
                2: "Variable placement.",
                3: "Frequency setting via own DMX control directly in the ballast.",
                4: "Additional control via DMX console and remotely via Wi-fi.",
                5: "¼ CTO,½ CTO,Full CTO",
                6: "Single Nett, Double Nett",
                7: "Plus Green, Minus Green",
                8: "For all Gaffair Balloons-1,2kw,2,4kw,4kw HMI"
              },
            },
          },
          tuby: {
            name: "Tube",
            miniDescription: "Options: LED RGBWW - Tungsten - HMI - Mix",
            description: "Tube is a lighting balloon that can be filled with helium or air. When filled with helium, the balloon floats and can be placed freely in the space. If filled with air, it must be suspended or lifted using a crane, to which it is securely attached."
          },
          kostky: {
            name: "Cube",
            miniDescription: "Options: LED RGBWW - Tungsten - HMI - Mix",
            description: "Cube is a lighting balloon that can be filled with helium or air. When filled with helium, the balloon floats and can be placed freely in the space. If filled with air, it must be suspended or lifted using a crane, to which it is securely attached.",
          },
          matrace: {
            name: "Cloud",
            miniDescription: "Options: LED RGBWW - Tungsten - HMI - Mix",
            description: "Cloud is a lighting balloon that can be filled with helium or air. When filled with helium, the balloon floats and can be placed freely in the space. If filled with air, it must be suspended or lifted using a crane, to which it is securely attached.",
          },
          koule: {
            name: "Balloon",
            miniDescription: "Options: LED RGWW - Tungsten - HMI - Mix",
            description: "Balloon is a lighting balloon that can be filled with helium or air. When filled with helium, the balloon floats and can be placed freely in the space. If filled with air, it must be suspended or lifted using a crane, to which it is securely attached.",
          },
        },
      },
    },
    de: {
      translation: {
        Home: "Zuhause",
        Before: "Vor",
        After: "Nach",
        AboutUs: "Über uns",
        Products: "Produkte",
        Projects: "Projekte",
        Gallery: "Galerie",
        GalleryModal: "Klicken Sie überall, um zu schließen",
        Contact: "Kontakt",
        ContactInfo: "Kontaktinformation",
        ContactFormTitle: "Kontaktformular",
        HomeHero: "Helium- und luftgefüllte Beleuchtungsballons",
        HomeAdvantages: "Unsere Vorteile",
        HomeHeroDes1:
          "Erstklassiges, innovatives Beleuchtungsmaterial, Zusammenstellung eines beispiellosen individuellen Angebots, erfahrenes Personal - das sind die Vorteile des Unternehmens",
        HomeHeroDes2: "Balloon - light - Prag",
        HomeHeroDes3:
          "s.r.o., das seit 1999 überliefert wurde. Wir bieten unsere Produkte jetzt auch in LED RGBWW-Versionen an.",
        HomeHeroButton: "Kontaktiere uns",
        HomeHeroButton2: "Erfahren Sie",
        AboutUsTitle: "Über uns",
        AboutUsDes1: "Das Unternehmen Balloon - light - Prag",
        AboutUsDes2: "s.r.o. bietet mit Helium und Luft gefüllte Ballons für die Innen- oder Außenbeleuchtung an. Beim Einsatz von Heliumballons bleibt die Lichtstärke gleich – das Ergebnis ist weiches Licht nahezu ohne Schatten. Daher findet dieses Produkt vor allem in der Filmindustrie Anwendung. Wir sind bestrebt, unsere Produkte ständig an neue Trends und Kundenbedürfnisse anzupassen.",
        AboutUsProjects: "Projekte die wir geholfen haben zu realisieren",
        AboutUsLocations: "Verschiedene Standorte",
        AboutUsYears: "Jahre",
        AboutUsYears2: "Jahre der Erfahrung",
        AboutUsProducts: "Produkte in der Angebot",

        WhyUsTitle: "Warum sollten Sie uns wählen?",
        WhyUsDes: "Wir verbinden höchste Qualität, Professionalität und modernes Design zu einer perfekten Lösung für jede Veranstaltung. Mit uns können Sie sicher sein, dass Ihre Veranstaltung wirklich glänzen wird.",
        WhyUsCards: {
          europeanMarketLeaders: {
            title: "Europäischer Marktführer",
            subtitle: "Wir sind das größte Unternehmen in Europa, das sich auf die Beleuchtung von Ballons spezialisiert hat. Unsere Erfahrung und unser Dienstleistungsangebot sind unübertroffen.",
          },
          bestMaterials: {
            title: "Die besten Materialien auf dem Markt",
            subtitle: "Wir verwenden nur erstklassige Materialien, die die Langlebigkeit, Haltbarkeit und Sicherheit eines jeden Ballons garantieren.",
          },
          professionalTeam: {
            title: "Professionelles Team von Experten",
            subtitle: "Unsere Spezialisten sind erfahrene Fachleute, die bereit sind, den reibungslosen Ablauf Ihrer Veranstaltung von der Planung bis zur Durchführung zu gewährleisten.",
          },
          hollywoodPartner: {
            title: "Partner von Hollywood-Produktionen",
            subtitle: "Unsere Ballons beleuchten Szenen in weltbekannten Filmen und Werbespots.",
          },
        },

        HomeProducts: "Unsere Produkte",
        HomeProductsButton: "Alle Produkte",
        ProductsProductsButton: "Mehr Produkte",
        HomeProjects: "Realisierte Projekte",
        HomeProjectsButton: "Alle Projekte",
        HomeGallery: "Galerie",
        HomeGalleryButton: "Ganze Galerie",
        FooterFormName: "Name",
        FooterFormEmail: "Email",
        FooterFormTopic: "Thema",
        FooterFormMessage: "Nachricht",
        FooterFormButton: "Senden",
        FooterPhone: "Telefonnummer",
        EmailResponse: "Dankeschön. Dies ist die Bestätigung, dass wir Ihre E-Mail erhalten haben.",

        ProjectsHero: "Schauen Sie sich die Projekte an,",
        ProjectsHero2: "an denen wir beteiligt waren.",
        ProjectsHeroSub:
          "Mehr als 2900 Projekte an über 2400 Standorten. Wir sind stolz darauf, mit führenden Akteuren im Bereich des digitalen Streamings zusammengearbeitet zu haben, darunter Netflix, Disney, Amazon Prime und andere.",
        ProjectsHeroButton: "Portfolio",
        GalleryHero1: "Beleuchtung, die die Geschichte vervollständigt,",
        GalleryHero2:
          "unsere Ballonlichter machen jede Szene zu einem Meisterwerk.",
        GalleryHeroButton: "Galerie",
        ProductCardButton: "Detaillierte Beschreibung",
        UnderWaterName: "Unterwasser-LED RGBWW-Leuchten",
        UnderWaterMiniDes: "Unterwasser - LED RGBWW - Licht",
        UnderWaterDes:
          "Unsere Unterwasserleuchten werden hauptsächlich in der Film- und TV-Branche eingesetzt: Blade Runner 2049 / Mission: Impossible 6 / Submergence / Kingsman: The golden circle",
        UnderWaterSpec1: "BiColor Unterwasserlicht",
        UnderWaterSpec2: "70 Watt @ 2700K (überall)",
        UnderWaterSpec3: "70 Watt @ 6500K (überall)",
        UnderWaterSpec4: "140 Watt @ ca. 4100K (nur unter Wasser)",
        UnderWaterSpec6:
          "variabler Einbau entlang der gesamten Länge des Lichts",
        UnderWaterSpec7: "15KHz-16KHz Frequenzeinstellung über DMX",
        UnderWaterSpec9: "luftlose Technologie - alles mit Polyurethan gefüllt",
        UnderWaterSpec10:
          "maximale Kabellänge/getestet auf 30 Metern im Ozean (in 2015 TV-Show Weiße Haie in SA)",
        products: {
          podvodniLedSvetla: {
            name: "Unterwasser Lichter",
            miniDescription: "Unterwasser - LED RGBWW - Licht",
            description:
              "Unsere Unterwasserleuchten werden hauptsächlich in der Film- und TV-Branche eingesetzt: Blade Runner 2049 / Mission: Impossible 6 / Submergence / Kingsman: The golden circle",
            categories: {
              Model: {
                model: "LED RGBWW - 4-foot Tube",
                size: "	1246mm x 56mm",
                power: "mehr als 140W",
              },
              Info: {
                0: "LED RBGWW Unterwasserlicht",
                1: "140 Watt @ 2700K (überall)",
                2: "140 Watt @ 6500K (überall)",
                3: "140 Watt @ rgb 4100K (nur unter Wasser)",
                4: "48V DC",
                5: "variabler Einbau entlang der gesamten Länge des Lichts",
                6: "Frequenzeinstellung über DMX",
                7: "IP-Rating: IP68 & IP69K",
                8: "luftlose Technologie - alles mit Polyurethan gefüllt",
              },
            },
          },
          noGravity: {
            name: "Kein Schwerkraftlicht",
            miniDescription: "Optionen: LED RGBWW",
            description:
              "Unsere Schwerkraftlicht werden hauptsächlich in der Film- und TV-Branche eingesetzt. Ihr Vorteil ist geringe Höhe, geringes Volumen an Helium, einfache Manipulation und Steuerung. Kann mit Luft gefüllt werden.",
            categories: {
              Info: {
                0: "Temperatur 2700K bis 6500K",
                1: "Quelle (Ballast) 220V",
                2: "Variable Platzierung.",
                3: "Frequenzeinstellung über eigene DMX-Steuerung direkt im Ballast.",
                4: "Zusätzliche Steuerung über DMX-Konsole und ferngesteuert über Wi-fi.",
              },
            },
          },
          suncut: {
            name: "SUNCUT ballons",
            miniDescription: "Optionen: Lichtschatten",
            description:
              "Unsere Beschattungsballons blockieren effektiv das direkte Sonnenlicht und ermöglichen eine präzise Lichtsteuerung.",
            categories: {
              Info: {
                0: "Temperatur 2700K bis 6500K",
                1: "Quelle (Ballast) 220V",
                2: "Variable Platzierung.",
                3: "Frequenzeinstellung über eigene DMX-Steuerung direkt im Ballast.",
                4: "Zusätzliche Steuerung über DMX-Konsole und ferngesteuert über Wi-fi.",
              },
            },
          },
          flatLight: {
            name: "Flaches Licht (Stativ)",
            miniDescription: "Optionen: LED RGBWW",
            description:"Unsere Flat Lights sind leicht und schlank und dank ihrer einzigartigen klappbaren Konstruktion einfach zu transportieren. Sie können auf einem Stativ montiert oder an Seilen aufgehängt werden. Sie sind beliebt wegen ihrer einfachen Handhabung und Steuerung. Flats kunnen in elke maat worden gebouwd - altijd met een lengte en breedte die een veelvoud van 4 zijn (bijv. 4+4+4+...×4+4+4+...). Flats bis zu 12x12 können in einem Stativ untergebracht werden.",            
            categories: {
              Info: {
                0: "Temperatur 2000K bis 10000K",
                1: "Zubehör: Eggcreate",
                2: "Frequenzeinstellung über eigene DMX-Steuerung direkt im Ballast.",
                3: "Zusätzliche Steuerung über DMX-Konsole und ferngesteuert über Wi-fi.",
                5: "Flats bis zu 12x12 können in einem Stativ untergebracht werden.",
                6: "Flats kunnen in elke maat worden gebouwd - altijd met een lengte en breedte die een veelvoud van 4 zijn (bijv. 4+4+4+...×4+4+4+...)."
              },
            },
          },
          elipsy: {
            name: "Stativballons",
            miniDescription: "Optionen: LED RGBWW - Tungsten - HMI - Mix light",
            description: "Unsere Stativballons werden mit Luft gefüllt und sind daher besonders leicht. Sie lassen sich einfach aufhängen – ganz ohne aufwendige Rigging-Systeme wie bei herkömmlichen Leuchten oder Panels. Beliebt sind sie wegen ihrer niedrigen Bauhöhe, der einfachen Handhabung und der unkomplizierten Steuerung. Dank ihrer kompakten Bauweise sind sie leicht zu transportieren.",
            categories: {
              Info: {
                0: "Temperatur 2700K bis 10000K",
                1: "Quelle (Ballast) 220/380V",
                2: "Variable Platzierung.",
                3: "Frequenzeinstellung über eigene DMX-Steuerung direkt im Ballast.",
                4: "Zusätzliche Steuerung über DMX-Konsole und ferngesteuert über Wi-fi.",
                5: "¼ CTO,½ CTO,Full CTO",
                6: "Single Nett, Double Nett",
                7: "Plus Green, Minus Green",
                8: "Für alle Gaffair Balloons-1,2kw,2,4kw,4kw HMI",
              },
            },
          },
          tuby: {
            name: "Röhrchen",
            miniDescription: "Optionen: LED RGBWW - Tungsten - HMI - Mix",
            description: "Röhrchen ist ein Leuchtballon, der mit Helium oder Luft gefüllt werden kann. Mit Helium gefüllt schwebt der Ballon und kann frei im Raum platziert werden. Wird er mit Luft gefüllt, muss er aufgehängt oder mit einem Kran angehoben und sicher befestigt werden.",
          },
          kostky: {
            name: "Würfel",
            miniDescription: "Optionen: LED RGBWW - Tungsten - HMI - Mix",
            description: "Würfel ist ein Leuchtballon, der mit Helium oder Luft gefüllt werden kann. Mit Helium gefüllt schwebt der Ballon und kann frei im Raum platziert werden. Wird er mit Luft gefüllt, muss er aufgehängt oder mit einem Kran angehoben und sicher befestigt werden.",
          },
          matrace: {
            name: "Matratze",
            miniDescription: "Optionen: LED RGBWW - Tungsten - HMI - Mix",
            description:  "Matratze ist ein Leuchtballon, der mit Helium oder Luft gefüllt werden kann. Mit Helium gefüllt schwebt der Ballon und kann frei im Raum platziert werden. Wird er mit Luft gefüllt, muss er aufgehängt oder mit einem Kran angehoben und sicher befestigt werden.",
          },
          koule: {
            name: "Kugel",
            miniDescription: "Optionen: LED RGBWW - Tungsten - HMI - Mix",
            description: "Kugel ist ein Leuchtballon, der mit Helium oder Luft gefüllt werden kann. Mit Helium gefüllt schwebt der Ballon und kann frei im Raum platziert werden. Wird er mit Luft gefüllt, muss er aufgehängt oder mit einem Kran angehoben und sicher befestigt werden.",
          },
        },
      },
    },
    es: {
      translation: {
        Home: "Casa",
        Before: "Antes",
        After: "Después",
        AboutUs: "Sobre nosotros",
        Products: "Productos",
        Projects: "Proyectos",
        Gallery: "Galería",
        GalleryModal: "Haga clic en cualquier lugar para cerrar",
        Contact: "Contacto",
        ContactInfo: "Información de contacto",
        ContactFormTitle: "Formulario de contacto",
        HomeHero: "Globos de iluminación llenos de helio y aire",
        HomeAdvantages: "Nuestras ventajas",
        HomeHeroDes1:
          "Material de iluminación innovador de primera clase, montaje de una oferta individual sin igual, personal experimentado: estas son las ventajas de la empresa",
        HomeHeroDes2: "Balloon - light - Praga",
        HomeHeroDes3:
          "s.r.o., que se ha transmitido desde 1999. Ahora también ofrecemos nuestros productos en versiones LED RGBWW.",
        HomeHeroButton: "Contáctenos",
        HomeHeroButton2: "Aprende más",
        AboutUsTitle: "Sobre nosotros",
        AboutUsDes1: "La empresa Balloon - light - Praga",
        AboutUsDes2: "s.r.o. ofrece globos llenos de helio y aire para iluminación interior o exterior. Cuando se utilizan globos de helio, el nivel de luz sigue siendo el mismo: el resultado es una luz suave y casi sin sombras. Esto significa que este producto se utiliza principalmente en la industria cinematográfica. Intentamos adaptar constantemente nuestros productos a las nuevas tendencias y necesidades de los clientes.",
        AboutUsProjects: "Proyectos en los que hemos ayudado a realizar",
        AboutUsLocations: "Ubicaciones diferentes",
        AboutUsYears: "Años",
        AboutUsYears2: "Años de experiencia",
        AboutUsProducts: "Productos en la oferta",

        WhyUsTitle: "¿Por qué elegirnos?",
        WhyUsDes: "Combinamos máxima calidad, profesionalidad y diseño moderno para crear la solución perfecta para cualquier evento. Con nosotros, puede estar seguro de que su evento brillará de verdad.",
        WhyUsCards: {
          europeanMarketLeaders: {
            title: "Líderes del mercado europeo",
            subtitle: "Somos la mayor empresa de Europa especializada en globos de iluminación. Nuestra experiencia y gama de servicios no tienen rival.",
          },
          bestMaterials: {
            title: "Los mejores materiales del mercado",
            subtitle: "Sólo utilizamos materiales de primera calidad que garantizan la longevidad, durabilidad y seguridad de cada globo.",
          },
          professionalTeam: {
            title: "Equipo profesional de expertos",
            subtitle: "Nuestros especialistas son profesionales experimentados dispuestos a garantizar el buen desarrollo de su evento, desde el diseño hasta la ejecución.",
          },
          hollywoodPartner: {
            title: "Socio de producciones de Hollywood",
            subtitle: "Nuestros globos iluminan escenas de películas y anuncios famosos en todo el mundo.",
          },
        },


        HomeProducts: "Nuestros productos",
        HomeProductsButton: "Todos los productos",
        ProductsProductsButton: "Más productos",
        HomeProjects: "Proyectos realizados",
        HomeProjectsButton: "Todos los proyectos",
        HomeGallery: "Galería",
        HomeGalleryButton: "Toda la galería",
        FooterFormName: "Nombre",
        FooterFormEmail: "Correo electrónico",
        FooterFormTopic: "Tema",
        FooterFormMessage: "Mensaje",
        FooterFormButton: "Enviar",
        FooterPhone: "Número de teléfono",
        EmailResponse: "Gracias. Esta es la confirmación de que hemos recibido su correo electrónico.",

        ProjectsHero: "Echa un vistazo a los proyectos,",
        ProjectsHero2: "en los que hemos participado.",
        ProjectsHeroSub:
          "Más de 2900 proyectos en más de 2400 ubicaciones. Estamos orgullosos de haber trabajado con los principales actores en el espacio de transmisión digital, incluidos Netflix, Disney, Amazon Prime y otros.",
        ProjectsHeroButton: "Portafolio",
        GalleryHero1: "Iluminación que completa la historia,",
        GalleryHero2:
          "nuestros globos de luz hacen de cada escena una obra maestra.",
        GalleryHeroButton: "Galería",
        ProductCardButton: "Descripción detallada",
        UnderWaterName: "Luces LED RGBWW submarinas",
        UnderWaterMiniDes: "Submarino - LED RGBWW - Luz",
        UnderWaterDes:
          "Nuestras luces submarinas se utilizan principalmente en la industria cinematográfica y televisiva: Blade Runner 2049 / Mission: Impossible 6 / Submergence / Kingsman: The golden circle",
        UnderWaterSpec1: "Luz submarina bicolor",
        UnderWaterSpec2: "70 Watts @ 2700K (en todas partes)",
        UnderWaterSpec3: "70 Watts @ 6500K (en todas partes)",
        UnderWaterSpec4: "140 Watts @ ca. 4100K (solo bajo el agua)",
        UnderWaterSpec6:
          "montaje variable a lo largo de toda la longitud de la luz",
        UnderWaterSpec7: "Ajuste de frecuencia de 15KHz-16KHz a través de DMX",
        UnderWaterSpec9: "tecnología sin aire: todo relleno de poliuretano",
        UnderWaterSpec10:
          "longitud máxima del cable/probado a 30 metros en el océano (en 2015 programa de televisión White Sharks en SA)",
        products: {
          podvodniLedSvetla: {
            name: "Luces submarinas",
            miniDescription: "Submarino - LED RGBWW - Luz",
            description:
              "Nuestras luces submarinas se utilizan principalmente en la industria cinematográfica y televisiva: Blade Runner 2049 / Mission: Impossible 6 / Submergence / Kingsman: The golden circle",
            categories: {
              Model: {
                model: "Tubo LED RGBWW de 4 pies",
                size: "1246mm x 56mm",
                power: "más de 140W",
              },
              Info: {
                0: "Luz submarina LED RGBWW",
                1: "140 Watts @ 2700K (en todas partes)",
                2: "140 Watts @ 6500K (en todas partes)",
                3: "140 Watts @ rgb 4100K (solo bajo el agua)",
                4: "48V DC",
                5: "montaje variable a lo largo de toda la longitud de la luz",
                6: "Ajuste de frecuencia de DMX",
                7: "IP-Rating: IP68 & IP69K",
                8: "tecnología sin aire: todo relleno de poliuretano",
              },
            },
          },
          noGravity: {
            name: "Luz sin gravedad",
            miniDescription: "Opciones: LED RGBWW",
            description:
              "Nuestras luces No Gravity se utilizan principalmente en la industria cinematográfica y televisiva. Su ventaja es la baja altura, el bajo volumen de helio, la fácil manipulación y el control. Se puede llenar con aire.",
            categories: {
              BiColor: {
                0: "Temperatura 2700K a 6500K",
                1: "Fuente (balasto) 220V",
                2: "Colocación variable.",
                3: "Ajuste de frecuencia a través de control DMX propio directamente en el balasto.",
                4: "Control adicional a través de consola DMX y de forma remota a través de Wi-fi.",
              },
            },
          },
          suncut: {
            name: "SUNCUT globos",
            miniDescription: "Opciones: Sombreado claro",
            description:
              "Nuestros globos de sombreado bloquean eficazmente la luz solar directa y permiten un control preciso de la luz",
            categories: {
              Info: {
                0: "Temperatura 2700K a 6500K",
                1: "Fuente (balasto) 220V",
                2: "Colocación variable.",
                3: "Ajuste de frecuencia a través de control DMX propio directamente en el balasto.",
                4: "Control adicional a través de consola DMX y de forma remota a través de Wi-fi.",
              },
            },
          },
          flatLight: {
            name: "Luz plana (Trípode)",
            miniDescription: "Opciones: LED RGBWW",
            description:"Nuestras Flat Lights son ligeras y delgadas, y gracias a su diseño plegable único, son fáciles de transportar. Se pueden montar en un trípode o colgar con cuerdas. Son populares por su facilidad de uso y manejo. Los pisos se pueden construir de cualquier tamaño, siempre con una longitud y una anchura que sean múltiplos de 4 (por ejemplo, 4+4+4+...×4+4+4+...). Los pisos de hasta 12x12 pueden colocarse en un trípode.",
            categories: {
              Info: {
                0: "Temperatura 2000K a 10000K",
                1: "Accesorios: Eggcreate",
                2: "Ajuste de frecuencia a través de control DMX propio directamente en el balasto.",
                3: "Control adicional a través de consola DMX y de forma remota a través de Wi-fi.",
                5: "Los pisos de hasta 12x12 pueden colocarse en un trípode.",
                6: "Los pisos se pueden construir de cualquier tamaño, siempre con una longitud y una anchura que sean múltiplos de 4 (por ejemplo, 4+4+4+...×4+4+4+...).",
              },
            },
          },
          elipsy: {
            name: "Globos trípode",
            miniDescription: "Opciones: LED RGBWW - Tungsten - HMI - Mix light",
            description: "Nuestros Globos de trípode se llenan de aire, por lo que son extremadamente ligeros. Se pueden colgar fácilmente sin sistemas de montaje complicados como los que requieren las luces o paneles tradicionales. Son populares por su bajo perfil, facilidad de manejo y control sencillo. Su diseño compacto los hace fáciles de transportar.",
            categories: {
              Info: {
                0: "Temperatura 2700K a 10000K",
                1: "Fuente (balasto) 220V/380",
                2: "Colocación variable.",
                3: "Ajuste de frecuencia a través de control DMX propio directamente en el balasto.",
                4: "Control adicional a través de consola DMX y de forma remota a través de Wi-fi.",
                5: "¼ CTO,½ CTO,Full CTO",
                6: "Single Nett, Double Nett",
                7: "Plus Green, Minus Green",
                8: "Para todos los globos Gaffair-1,2kw,2,4kw,4kw HMI",
              },
            },
          },
          tuby: {
            name: "Tubos",
            miniDescription: "Opciones: LED RGBWW - Tungsten - HMI - Mix",
            description: "Tubos es un globo de iluminación que se puede llenar con helio o aire. Cuando se llena con helio, el globo flota y puede colocarse libremente en el espacio. Si se llena con aire, debe colgarse o elevarse con una grúa, a la que se fija de forma segura.",
          },
          kostky: {
            name: "Cubo",
            miniDescription: "Opciones: LED RGBWW - Tungsten - HMI - Mix",
            description: "Cubo es un globo de iluminación que se puede llenar con helio o aire. Cuando se llena con helio, el globo flota y puede colocarse libremente en el espacio. Si se llena con aire, debe colgarse o elevarse con una grúa, a la que se fija de forma segura.",
          },
          matrace: {
            name: "Colchón",
            miniDescription: "Opciones: LED RGBWW - Tungsten - HMI - Mix",
            description: "Colchón es un globo de iluminación que se puede llenar con helio o aire. Cuando se llena con helio, el globo flota y puede colocarse libremente en el espacio. Si se llena con aire, debe colgarse o elevarse con una grúa, a la que se fija de forma segura.",
          },
          koule: {
            name: "Bolas",
            miniDescription:
              "Opciones: LED RGBWW - Tungsten - HMI - Mix",
            description: "Bola es un globo de iluminación que se puede llenar con helio o aire. Cuando se llena con helio, el globo flota y puede colocarse libremente en el espacio. Si se llena con aire, debe colgarse o elevarse con una grúa, a la que se fija de forma segura.",
          },
        },
      },
    },
  },
  lng: "cs",
  fallbackLng: "en",

  interpolation: {
    escapeValue: false,
  },
});
