const images = require.context(
  "../images/products",
  false,
  /\.(webp)$/
);
const imagePaths = images.keys().map(images);

const podvodniLedImages = require.context(
  "../images/products/podvodníLed/",
  false,
  /\.(webp)$/
);
const podvodniLedPaths = podvodniLedImages.keys().map(podvodniLedImages);

const noGravity = require.context(
  "../images/products/noGravity/",
  false,
  /\.(webp)$/
);
const noGravityPaths = noGravity.keys().map(noGravity);


const suncut = require.context(
  "../images/products/suncut/",
  false,
  /\.(webp)$/
);
const suncutPaths = suncut.keys().map(suncut);


const flatLight = require.context(
  "../images/products/flatLight/",
  false,
  /\.(webp)$/
);
const flatLightPaths = flatLight.keys().map(flatLight);

const elipsy = require.context(
  "../images/products/elipsy/",
  false,
  /\.(webp)$/
);
const elipsyPaths = elipsy.keys().map(elipsy);

const tuby = require.context(
  "../images/products/tuby/",
  false,
  /\.(webp)$/
);
const tubyPaths = tuby.keys().map(tuby);

const krychle = require.context(
  "../images/products/kostky/",
  false,
  /\.(webp)$/
);
const krychlePaths = krychle.keys().map(krychle);

const matrace = require.context(
  "../images/products/matrace/",
  false,
  /\.(webp)$/
);
const matracePaths = matrace.keys().map(matrace);

const koule = require.context(
  "../images/products/koule/",
  false,
  /\.(webp)$/
);
const koulePaths = koule.keys().map(koule);

export const products = [
  {
    id: 2,
    slug: "no-gravity-light",
    translationKey: "noGravity",
    name: "NO GRAVITY LIGHT",
    miniDescription: "Možnosti: LED RGBWW",
    description:
      "Naše No Gravity světla jsou využívaná především ve filmovém a TV průmyslu. Jejich výhodou jsou nízká výška, nízký objem helia, snadná manipulace i ovládání. Lze plnit i vzduchem.",
    image: imagePaths[6],
    detailImages: noGravityPaths,
    categories: {
      LED_RGBWW: [
        {
          model: "No Gravity Blacktop",
          size: "3x3x1m",
          power: "300 W each color",
        },
        {
          model: "No Gravity Blacktop",
          size: "2.8x2.8x0.8m",
          power: "300 W each color",
        },
      ],
      Info: [
        "Teplota 2700K až 6500K",
        "Zdroj (ballast) 220V",
        "Variabilní umístění.",
        "Nastavení frekvence přes vlastní DMX ovládání přímo v ballastu.",
        "Další možnost ovládání přes DMX pult i vzdáleně přes Wi-fi.",
      ],
    },
  },
  {
    id: 3,
    slug: "suncut",
    translationKey: "suncut",
    name: "SUNCUT balóny",
    miniDescription: "Možnosti: Stínění",
    description:
      "Naše stínící balony efektivně blokují přímé sluneční paprsky a umožňují precizní kontrolu světla",
    image: imagePaths[8],
    detailImages: suncutPaths,
    categories: {
      Info: [
        {
          model: "Cloud",
          type: "Blacktop/White",
          size: "2,5m x 2,5m"
        },
        {
          model: "Cloud",
          type: "Blacktop/White",
          size: "3m x 3m",
        },
        {
          model: "Cloud",
          type: "Blacktop/White",
          size: "4,5m x 4,5m",
        },
        {
          model: "Cloud",
          type: "Blacktop/White",
          size: "6m x 6m",
        },
        {
          model: "Cloud",
          type: "Blacktop/White",
          size: "8m x 6m",
        },
      ],

    },
  },
  {
    id: 4,
    slug: "flat-light",
    translationKey: "flatLight",
    name: "FLAT LIGHT ( LED RGBWW stativová )",
    miniDescription: "Možnosti: LED RGBWW",
    description: "Naše Flat Light jsou lehká a úzká, díky unikátní skládací konstrukci nenáročná pro přepravu. Lze je umístit na stativ, případně zavěsit na lanech. Jsou oblíbená pro svou snadnou manipulaci i ovládání.", image: imagePaths[0],
    detailImages: flatLightPaths,
    categories: {
      LED_RGBWW: [
        {
          model: "Flat light",
          size: "4x4 Feet",
          power: "300 - 500 W each color"
        },
        {
          model: "Flat light",
          size: "4x8 Feet",
          power: "600 - 1000 W each color"
        },
        {
          model: "Flat light",
          size: "8x8 Feet",
          power: "1200 - 2000 W each color"
        },
        {
          model: "...",
          size: "...",
          power: "..."
        }
      ],
      Info: [
        "Teplota 2000K až 6500K",
        "Doplňky: Eggcreate",
        "Nastavení frekvence přes vlastní DMX ovládání přímo v ballastu.",
        "Další možnost ovládání přes DMX pult i vzdáleně přes Wi-fi.",
        "Vše v ceně: Rámeček, sukně, Eggcreate pro každou velikost",
        "Flaty do rozměru 12x12 lze umístit do stativu",
        "Flaty lze sestavit do libovolných rozměrů – vždy s délkou i šířkou, které jsou násobkem 4 (např. 4+4+4+… × 4+4+4+…).",
      ],
    },
  },
  {
    id: 5,
    slug: "stative",
    translationKey: "elipsy",
    name: "Stativové Balóny",
    miniDescription: "Možnosti: LED RGBWW - Tungsten - HMI",
    description:
      "Naše Pad Light (stative) se plní vzduchem a jsou tedy velice lehká. Lze je umístit na stativ případně kamkoli klemou bez složitých rigingů jako u běžných světel či panelů. Jsou oblíbená pro jejich nízkou výšku, snadnou manipulaci i ovládání. Skladná nenáročná pro přepravu.",
    image: imagePaths[4],
    detailImages: elipsyPaths,
    categories: {
      "PadLight": [
        {
          model: "Pad Ligth",
          type: "LED RGBWW",
          size: "120cm x 120cm x 30cm",
          power: "Power 300 W each color",
        }
      ],
      "SpringBall": [
        {
          model: "80 Springball",
          type: "LED RGBWW",
          power: "300 W",
        },
        {
          model: "140 Springball",
          type: "LED RGBWW",
          power: "600 W",
        },
        {
          model: "3-K Springball",
          type: "Tungsten",
          power: "3x 1000 W",
        },
        {
          model: "6-K Springball",
          type: "Tungsten",
          power: "6x 1000 W",
        },
      ],
      "Gaffair": [
        {
          model: "Gaffair 575",
          type: "HMI",
          power: "575 W"
        },
        {
          model: "Gaffair 1200",
          type: "HMI",
          power: "1200 W"
        },
        {
          model: "Gaffair 2500",
          type: "HMI",
          power: "2500 W",
        },
        {
          model: "Gaffair 4000",
          type: "HMI",
          power: "4000 W",
        },
        {
          model: "Gaffair 6000",
          type: "HMI",
          power: "6000 W",
        },
      ],
      Info: [
        "Teplota 2700K až 6500K",
        "Zdroj (ballast) 220V",
        "Variabilní umístění.",
        "Nastavení frekvence přes vlastní DMX ovládání přímo v ballastu.",
        "Další možnost ovládání přes DMX pult i vzdáleně přes Wi-fi.",
        "¼ CTO,½ CTO,Full CTO",
        "Single Nett, Double Nett",
        "Plus Green, Minus Green",
        "For all Gaffair Balloons1,2kw,2,4kw,4kw HMI"
      ],
    },
  },
  {
    id: 6,
    slug: "tube",
    translationKey: "tuby",
    name: "TUBY",
    miniDescription: "Možnosti: LED RGBWW - Tungsten - HMI - Mix",
    description: "Možnosti: LED RGBWW - Tungsten - HMI - Mix",
    image: imagePaths[1],
    detailImages: tubyPaths,
    categories: {
      LED_RGBWW: [
        {
          model: "TUBE Blacktop",
          size: "500cm x 250cm",
          power: "1000 W each color",
        },
        {
          model: "TUBE Blacktop",
          size: "700cm x 200cm",
          power: "3x300 W each color",
        },
        {
          model: "TUBE Blacktop",
          size: "800cm x 250cm",
          power: "2000 W each color",
        },
        {
          model: "TUBE Blacktop",
          size: "970cm x 370cm",
          power: "4000 W each color",
        },
        
      ],
      Tungsten: [
        {
          model: "Tube",
          size: "120x400cm",
          power: "4kW"
        },
        {
          model: "Tube",
          size: "200x500cm",
          power: "10kW"
        },
        {
          model: "Tube",
          size: "250x500cm",
          power: "12-20kW"
        },
        {
          model: "Tube",
          size: "250x740cm",
          power: "14-26kW"
        },
        {
          model: "Tube",
          size: "370x700cm",
          power: "30-50kW"
        },
      ],
      HMI: [
        {
          model: "Tube",
          size: "200x500cm",
          power: "2.4kW"
        },
        {
          model: "Tube",
          size: "250x500cm",
          power: "4.8kW"
        },
        {
          model: "Tube",
          size: "250x740cm",
          power: "5-10kW"
        },
        
        {
          model: "Tube",
          size: "250x780cm",
          power: "10-16kW"
        },
        {
          model: "Tube",
          size: "370x700cm",
          power: "16-32kW"
        },
        {
          model: "Tube",
          size: "370x970cm",
          power: "32kW"
        },
        
      ],
      Mix: [
        {
          model: "Tube",
          size: "200x500cm",
          power: "2x1200 W HMI+6x1000 W Tungsten"
        },
        {
          model: "Tube",
          size: "250x740cm",
          power: "12 kw Tungsten+4,8 kw HMI"
        },
        {
          model: "Tube",
          size: "370x700cm",
          power: "20 kw Tungsten+10 kw HMI"
        },
        {
          model: "Tube",
          size: "370x700cm",
          power: "30 kw Tungsten+16 kw HMI"
        },
      ],
    },
  },
  {
    id: 7,
    slug: "cube",
    translationKey: "kostky",
    name: "KOSTKY",
    miniDescription: "Možnosti: LED RGBWW - Tungsten - HMI - Mix",
    description: "Možnosti: LED RGBWW - Tungsten - HMI - Mix",
    image: imagePaths[5],
    detailImages: krychlePaths,
    categories: {
      LED_RGBWW: [
        {
          model: "Cube Blacktop",
          size: "3.5x3.5x3.5m",
          power: "2 800 W each color",
        },
        {
          model: "Cube Blacktop",
          size: "2.5x2.5x2.5m",
          power: "1 400 W each color",
        },
      ],
      Tungsten: [
        {
          model: "Cube 20",
          size: "2x2x2m",
          power: "5kW	"
        },
        {
          model: "Cube 35",
          size: "3.5x3.5x3.5m",
          power: "30KW"
        },
        {
          model: "Cube 35",
          size: "	3.5x3.5x3.5m",
          power: "10kW"
        },
      ],
      HMI: [
        {
          model: "Cube 20 HMI",
          size: "2x2x2m",
          power: "1.2kW"
        },
        {
          model: "Cube 35 HMI",
          size: "3.5x3.5x3.5m	",
          power: "10kW"
        },
        {
          model: "Cube 35 HMI",
          size: "3.5x3.5x3.5m	",
          power: "16kW"
        },
        
      ],
      Mix: [
        {
          model: "Cube 25",
          size: "2.5x2.5x2.5m",
          power: "1.2kW HMI + 3kW Tungsten"
        },
        {
          model: "Cube 35",
          size: "3.5x3.5x3.5m",
          power: "8kW HMI + 15kW Tungsten"
        },
      ],
    },
  },
  {
    id: 8,
    slug: "matrace",
    translationKey: "matrace",
    name: "MATRACE",
    miniDescription: "Možnosti: LED RGBWW - Tungsten - HMI - Mix ",
    description: "Možnosti: LED RGBWW - Tungsten - HMI - Mix ",
    image: imagePaths[3],
    detailImages: matracePaths,
    categories: {
      LED_RGBWW: [
        {
          model: "Cloud Blacktop",
          size: "4.5x4.5x1",
          power: "1400 W-2800 W each color",
        },
        {
          model: "Cloud Blacktop",
          size: "4.5x4.5x1",
          power: "4x300 W each color",
        },
        {
          model: "Cloud Blacktop",
          size: "8x6x1",
          power: "4700 W each color",
        },
      ],
      Tungsten: [
        {
          model: "Cloud Blacktop",
          size: "2.5x2.5x1.3m",
          power: "5x 1000 W Tungsten"
        },
        {
          model: "Cloud Blacktop",
          size: "4.5x4.5x1.1m",
          power: "10x1000 W Tungsten"
        },
        {
          model: "Cloud Blacktop",
          size: "6x6x1.1m",
          power: "15-30 000 W Tungsten"
        },
        {
          model: "Cloud Blacktop",
          size: "6x8x1.1m",
          power: "15-30 000 W Tungsten"
        },
      ],
      HMI: [
        {
          model: "Cloud Blacktop",
          size: "2.5x2.5x1.3m",
          power: "1x 1200 W HMI"
        },
        {
          model: "Cloud Blacktop",
          size: "4.5x4.5x1m",
          power: "4x 1200 W HMI"
        },
        {
          model: "Cloud Blacktop",
          size: "6x6x1m",
          power: "9x 1200 W HMI"
        },
        {
          model: "Cloud Blacktop",
          size: "6x8x1m",
          power: "9x 1200 W HMI"
        },
      ],
      Mix: [
        {
          model: "Cloud Blacktop",
          size: "4.5x4.5x1m",
          power: " 6x 1 000 W Tungsten+2x1 200 W HMI",
        },
        {
          model: "Cloud Blacktop",
          size: "6x6x1m",
          power: "9x 1 200 W HMI+15x1 000 W Tungsten",
        },
        {
          model: "Cloud Blacktop",
          size: "6x8x1m",
          power: "9x 1 200 W HMI+15x 1000 W Tungsten",
        },
      ],
    },
  },
  {
    id: 9,
    slug: "balloon",
    translationKey: "koule",
    name: "KOULE",
    miniDescription: "Možnosti: HMI - Tungsten - Mix light - LED RGBWW",
    description: "Možnosti: HMI - Tungsten - Mix light - LED RGBWW",
    image: imagePaths[2],
    detailImages: koulePaths,
    categories: {
      LED_RGBWW: [
        {
          model: "Balloon 370",
          size: "Diameter 3.7m",
          power: "1400 W each color",
        },
        {
          model: "Balloon Ellipse 370",
          size: "4.3m x 3.7m",
          power: "1400 W each color",
        },
        {
          model: "Balloon 500",
          size: "Diameter 5m",
          power: "2800 W each color",
        },
      ],
      Tungsten: [
        {
          model: "Balloon 200",
          size: "Diameter 2.0m",
          power: "5kW"
        },
        {
          model: "Balloon 250",
          size: "Diameter 2.5m",
          power: "5kW"
        },
        {
          model: "Balloon 300",
          size: "Diameter 3.0m",
          power: "10kW"
        },
        {
          model: "Balloon 370",
          size: "Diameter 3.7m",
          power: "15kW"
        },
        {
          model: "Balloon Ellipse 550",
          size: "4.2m x 5.5m",
          power: "15-30kW"
        },
      ],
      HMI: [
        {
          model: "Balloon 250",
          size: "Diameter 2.5m",
          power: "2.4kW"
        },
        {
          model: "Balloon 300",
          size: "Diameter 3.0m",
          power: "2.4kW"
        },
        {
          model: "Balloon 370",
          size: "Diameter 3.7m",
          power: "4.8-8kw"
        },
        {
          model: "Balloon 420",
          size: "Diameter 4.2m",
          power: "8kw"
        },
        {
          model: "Balloon 500",
          size: "Diameter 5.0m",
          power: "16kw"
        },
        {
          model: "Balloon Ellipse 550",
          size: "4.2m x 5.5m",
          power: "10-16kw"
        }
      ],
      Mix: [
        {
          model: "Balloon 370",
          size: "Diameter 3.7m",
          power: "2x 1200 W HMI + 9x 1000 W Tungsten",
        },
        {
          model: "Balloon Ellipse 420",
          size: "3.7m x 4.2m",
          power: "5 kw HMI + 15 kw Halogen",
        },
        {
          model: "Balloon Ellipse 420",
          size: "3.7m x 4.2m",
          power: "8 kw HMI + 15 kw Halogen",
        },
        {
          model: "Balloon 500",
          size: "Diameter 5.0m",
          power: "8 kw HMI + 15 kw Halogen",
        },
        {
          model: "Balloon Ellipse 550",
          size: "4.2m x 5.5m",
          power: "16 Kw HMI+15x1000 W Tungsten",
        },
      ],
    },
  },
  {
    id: 1,
    slug: "underwater-ledw-light",
    translationKey: "podvodniLedSvetla",
    name: "PODVODNÍ SVĚTLA",
    miniDescription: "Underwater - LED RGBWW - Light",
    description:
      "Naše podvodní světla jsou využívaná především ve filmovém a TV průmyslu: Blade Runner 2049 / Mission: Impossible 6 / Submergence / Kingsman: The golden circle",
    image: imagePaths[7],
    detailImages: podvodniLedPaths,
    categories: {
      Model: [
        {
          model: "LED RGBWW - 4-foot Tube",
          size: "	1246mm x 56mm",
          power: "140W+",
        },
      ],
      Info: [
        "LED RGBWW podvodní světlo",
        "140 Watts @ 2700K (všude)",
        "140 Watts @ 6500K (všude)",
        "140 Watts @ rgb 4100K (pouze pod vodou)",
        "24V DC",
        "variabilní uchycení po celé délce světla",
        "15KHz-16KHz nastavení frekvence přes DMX",
        "IP-Rating: IP68 & IP69K",
        "bez vzduchová technologie - vše vyplněno polyuretanem",
      ],
    },
  },
];