import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);

  const languageOptions = [
    { value: 'cs', label: 'CS' },
    { value: 'en', label: 'EN' },
    { value: 'de', label: 'DE' },
    { value: 'es', label: 'ES' },
  ];

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      background: 'white',
      color: 'white',
      borderColor: state.isFocused ? '#3498db' : '#ccc',
      boxShadow: state.isFocused ? '0 0 0 1px #3498db' : 'none',
      borderRadius: '1rem',
      '&:hover': {
        borderColor: state.isFocused ? '#3498db' : '#ccc',
      },
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      backgroundColor: state.isSelected ? '#3498db' : 'white',
      '&:hover': {
        backgroundColor: state.isSelected ? '#3498db' : '#f2f2f2',
      },
    }),
  };

  const changeLanguage = (selectedOption) => {
    const newLanguage = selectedOption.value;
    i18n.changeLanguage(newLanguage);
    setSelectedLanguage(newLanguage);
  };

  return (
    <Select
      options={languageOptions}
      value={{ value: selectedLanguage, label: selectedLanguage }}
      onChange={changeLanguage}
      styles={customStyles}
      className="react-select-container"
      classNamePrefix="react-select"
    />
  );
};

export default LanguageSwitcher;
