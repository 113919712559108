import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Logo from "../../../images/logo.webp";
import LanguageSwitcher from "../LanguageSwitcher";
import Sidebar from "../sidebar/sidebar";
import "./navbar.css";

export default function Navbar() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  // Stav pro viditelnost navbaru, poslední pozici scrollu, příznak automatického scrollu a příznak nedávné navigace
  const [isNavbarVisible, setIsNavbarVisible] = useState(true);
  const [lastScrollPos, setLastScrollPos] = useState(window.scrollY);
  const [isAutoScrolling, setIsAutoScrolling] = useState(false);
  const [justNavigated, setJustNavigated] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      // Pokud právě probíhá automatické scrollování nebo jsme těsně po navigaci, neprovádíme skrývání navbaru
      if (isAutoScrolling || justNavigated) return;

      const currentScrollPos = window.scrollY;
      const windowWidth = window.innerWidth;

      if (windowWidth > 1000) {
        // Skrýt navbar, pokud uživatel scrolluje dolů
        setIsNavbarVisible(currentScrollPos < lastScrollPos);
        setLastScrollPos(currentScrollPos);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [lastScrollPos, isAutoScrolling, justNavigated]);

  // Funkce smoothScroll, která provede plynulé scrollování a po jeho dokončení resetuje stavy
  const smoothScroll = (targetPosition) => {
    setIsAutoScrolling(true);
    window.scrollTo({
      top: targetPosition,
      behavior: "smooth",
    });

    setTimeout(() => {
      setIsAutoScrolling(false);
      setLastScrollPos(targetPosition);
      setIsNavbarVisible(true);
    }, 800);
  };

  // Pokud jsme na Home a je nastavený stav scrollToAbout, provedeme scrollování
  useEffect(() => {
    if (location.pathname === "/" && location.state?.scrollToAbout) {
      const aboutSection = document.getElementById("aboutus-section");
      if (aboutSection) {
        setTimeout(() => {
          smoothScroll(aboutSection.offsetTop);
        }, 200);
      }
      navigate("/", { replace: true, state: {} });
    }
  }, [location.pathname, location.state, navigate]);

  // Při každé změně cesty resetujeme scroll pozici a nastavíme příznak nedávné navigace,
  // který na krátkou dobu (800 ms) deaktivuje skrývání navbaru.
  useEffect(() => {
    setIsNavbarVisible(true);
    setLastScrollPos(window.scrollY);
    setJustNavigated(true);
    const timer = setTimeout(() => {
      setJustNavigated(false);
    }, 800);
    return () => clearTimeout(timer);
  }, [location.pathname]);

  const handleAboutClick = (e) => {
    e.preventDefault();
    if (location.pathname === "/") {
      const aboutSection = document.getElementById("aboutus-section");
      if (aboutSection) {
        smoothScroll(aboutSection.offsetTop);
      }
    } else {
      navigate("/", { state: { scrollToAbout: true } });
    }
  };

  const scrollToBottom = (e) => {
    e.preventDefault();
    smoothScroll(document.documentElement.scrollHeight);
  };

  return (
    <div
      className={`navbarWrapper flex flex-row items-center mx-auto w-full justify-evenly transition-transform duration-300 ${
        isNavbarVisible ? "translate-y-0" : "-translate-y-full"
      }`}
    >
      {/* Logo */}
      <Link to="/">
        <img src={Logo} alt="logo" className="w-[10rem] h-auto mr-4" />
      </Link>

      {/* Odkazy – viditelné pouze na větších obrazovkách */}
      <nav className="hidden md:flex h-[50px] md:items-center md:p-4 md:rounded-xl">
        <div className="flex gap-6 font-semibold md:gap-4">
          <Link to="/" className="navLink">
            {t("Home")}
          </Link>
          <a href="/about" onClick={handleAboutClick} className="navLink">
            {t("AboutUs")}
          </a>
          <Link to="/products" className="navLink">
            {t("Products")}
          </Link>
          <Link to="/portfolio" className="navLink">
            {t("Projects")}
          </Link>
          <Link to="/gallery" className="navLink">
            {t("Gallery")}
          </Link>
          <a href="/contact" onClick={scrollToBottom} className="navLink">
            {t("Contact")}
          </a>
        </div>
      </nav>

      {/* Language Switcher */}
      <div className="ml-5">
        <LanguageSwitcher />
      </div>

      {/* Mobilní Sidebar */}
      <div className="md:hidden">
        <Sidebar />
      </div>
    </div>
  );
}
