// Sidebar.js
import React from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import "./sidebar.css";
import { useTranslation } from "react-i18next";

export default function Sidebar() {
  const [state, setState] = React.useState(false);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const scrollToBottom = (e) => {
    e.preventDefault();
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const handleAboutClick = (e) => {
    e.preventDefault();
    if (location.pathname === "/") {
      const aboutSection = document.getElementById("about-section");
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: "smooth" });
      }
    } else {
      navigate("/", { state: { scrollToAbout: true } });
    }
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState(open);
  };

  const sideList = (
    <div
      className="px-6 pt-6"
      style={{ width: 230 }}
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <IconButton
        onClick={toggleDrawer(false)}
        style={{
          position: "absolute",
          right: 20,
          top: 20,
        }}
      >
        <CloseIcon fontSize="large" />
      </IconButton>

      {/* Example routes - adapt them to your needs */}
      <List className="mt-16">
        <ListItem className="listItem" component={Link} to="/">
          {t("Home")}
        </ListItem>
        <ListItem
          className="listItem"
          onClick={handleAboutClick}
          component={Link}
          to="/about"
        >
          {t("AboutUs")}
        </ListItem>
        <ListItem className="listItem" component={Link} to="/products">
          {t("Products")}
        </ListItem>
        <ListItem className="listItem" component={Link} to="/portfolio">
          {t("Projects")}
        </ListItem>
        <ListItem className="listItem" component={Link} to="/gallery">
          {t("Gallery")}
        </ListItem>
        <ListItem
          className="listItem"
          onClick={scrollToBottom}
          component={Link}
          to="/contact"
        >
          {t("Contact")}
        </ListItem>
      </List>
    </div>
  );

  return (
    <>
      <IconButton onClick={toggleDrawer(true)}>
        <MenuIcon fontSize="large" style={{ color: "white" }} />
      </IconButton>
      <Drawer anchor="right" open={state} onClose={toggleDrawer(false)}>
        {sideList}
      </Drawer>
    </>
  );
}
