import React, { useRef } from "react";
import "./whyUs.css";
import { motion, useScroll, useTransform } from "framer-motion";
import StackedLineChartIcon from "@mui/icons-material/StackedLineChart";
import StarIcon from "@mui/icons-material/Star";
import Groups3Icon from "@mui/icons-material/Groups3";
import HandshakeIcon from "@mui/icons-material/Handshake";
import { useTranslation } from "react-i18next";
import SpotLightTwo from "../ui/SpotLight/Spotlighttwo";

function WhyUs() {
  const { t } = useTranslation();

  const data = [
    {
      icon: <StackedLineChartIcon fontSize="large" style={{ color: "orange" }} />,
      heading: t("WhyUsCards.europeanMarketLeaders.title"),
      text: t("WhyUsCards.europeanMarketLeaders.subtitle")
    },
    {
      icon: <StarIcon fontSize="large" style={{ color: "orange" }} />,
      heading: t("WhyUsCards.bestMaterials.title"),
      text: t("WhyUsCards.bestMaterials.subtitle")
    },
    {
      icon: <Groups3Icon fontSize="large" style={{ color: "orange" }} />,
      heading: t("WhyUsCards.professionalTeam.title"),
      text: t("WhyUsCards.professionalTeam.subtitle")
    },
    {
      icon: <HandshakeIcon fontSize="large" style={{ color: "orange" }} />,
      heading: t("WhyUsCards.hollywoodPartner.title"),
      text: t("WhyUsCards.hollywoodPartner.subtitle")
    }
  ];

  return (
    <div className="whyus-wrapper">
      <SpotLightTwo />
      <div className="col1">
        <h1>{t("HomeAdvantages")}</h1>
      </div>
      <div className="col2">
        {data.map((article, index) => {
          const articleNumber = index + 1;
          return (
            <WhyUsArticle
              key={index}
              article={article}
              articleNumber={articleNumber}
            />
          );
        })}
      </div>
    </div>
  );
}

function WhyUsArticle({ article, articleNumber }) {
  const container = useRef(null);
  const { scrollYProgress } = useScroll({
    target: container,
    offset: ["start end", "start start"]
  });
  const article_opacity = useTransform(scrollYProgress, [0, 0.7], [0, 1]);
  const article_scale = useTransform(scrollYProgress, [0, 0.5], [0.5, 1]);
  
  return (
    <div ref={container} className="article-container">
      <motion.div
        className="whyus-article"
        data-index={articleNumber}
        style={{ opacity: article_opacity, scale: article_scale }}
      >
        <h1>
          {article.icon} {article.heading}
        </h1>
        <p>{article.text}</p>
      </motion.div>
    </div>
  );
}

export default WhyUs;
