// src/components/ParticlesComponent.jsx
import React, { useCallback, useMemo } from "react";
import Particles from "react-tsparticles";
import { loadSlim } from "tsparticles-slim";

const ParticlesComponent = () => {
  const particlesInit = useCallback(async (engine) => {
    await loadSlim(engine); // Načte jen základní moduly
  }, []);

  const particleParams = useMemo(() => ({
    fpsLimit: 60,
    particles: {
      number: {
        value: 200,
        density: { enable: true, area: 800 },
      },
      color: { value: "#b0b0b0" },
      shape: {
        type: "circle",
        stroke: { width: 0, color: "#000000" },
        polygon: { sides: 5 },
      },
      opacity: {
        value: 0.2,
        random: false,
      },
      size: {
        value: 0.7,
        random: true,
      },
      move: {
        enable: true,
        speed: 0.5,
        direction: "top",
        random: false,
        straight: false,
        outModes: { default: "out" }, // Novější verze používá "outModes"
      },
    },
    interactivity: {
      events: {
        onHover: { enable: false },
        onClick: { enable: false },
        resize: true,
      },
    },
    retina_detect: true,
  }), []);

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      options={particleParams}
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    />
  );
};

export default ParticlesComponent;
