import React from "react";
import "../footer/footer.css";
import ContactForm from "./contact";

import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <>
      <footer className="footer-container mx-[3%] mt-[3%]">
        <div className="footer-inner-content xl:mx-[5%] flex">
          <div className="company-info font-light xl:mr-5">
          <div class="Infotxt">{t('ContactInfo')}</div>
            <p>Ballon-Light-Praha s.r.o.</p>
            <p>IČ: 28006739</p>
            <p>DIČ: CZ28006739</p>
            <p>Za Zástávkou 377/3, 109 00 Praha-Dolní Měcholupy, Hala č. 6</p>
            <br />
            <p>
              Email:{" "}
              <a href="mailto:info@balloonlightprag.cz">
                info@balloonlightprag.cz
              </a>
            </p>
            <p>{t('FooterPhone')}: <a href="tel:+420603452130">+420603452130</a></p>
            
          </div>
          <ContactForm />
        </div>
      </footer>
      <div className="flex justify-center p-3">
        COPYRIGHT © 2025 BALLOON LIGHT PRAG.&nbsp;
        <a href="http://www.zemlasoft.com" target="_blank" rel="noreferrer">
          MADE BY ZEMLASOFT
        </a>
      </div>
    </>
  );
}
