import React, { useState, useEffect, useRef } from 'react';
import { motion, useAnimation } from 'framer-motion';

const CountUp = ({ number, className }) => {
    const controls = useAnimation();
    const [count, setCount] = useState(0);
    const ref = useRef();
    const requestRef = useRef();
    const startTimeRef = useRef();

    const totalDuration = 2500; // Total duration in milliseconds

    const animateCount = (timestamp) => {
        if (!startTimeRef.current) startTimeRef.current = timestamp;
        const elapsedTime = timestamp - startTimeRef.current;
        const progress = elapsedTime / totalDuration;
        
        const nextCount = Math.min(Math.round(progress * number), number);
        setCount(nextCount);

        if (nextCount < number) {
            requestRef.current = requestAnimationFrame(animateCount);
        }
    };

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    controls.start({ opacity: 1, y: 0 });
                    requestRef.current = requestAnimationFrame(animateCount);
                } else {
                    controls.start({ opacity: 0, y: 20 });
                }
            },
            {
                threshold: 0.5,
            }
        );

        observer.observe(ref.current);

        return () => {
            observer.disconnect();
            if (requestRef.current) {
                cancelAnimationFrame(requestRef.current);
            }
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [number, controls]);

    return (
        <motion.span
            ref={ref}
            initial={{ opacity: 0, y: 20 }}
            animate={controls}
            transition={{ type: 'spring', stiffness: 100 }}
            className={className}
        >
            {count}
        </motion.span>
    );
};

export default CountUp;
