import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import "../footer/footer.css"; // Keeping your original styles
import { useTranslation } from "react-i18next";

export default function ContactForm() {
  const { t } = useTranslation();

  // Form data states
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  // Validation states
  const [formValidity, setFormValidity] = useState({
    name: false,
    email: false,
    subject: false,
    message: false,
  });

  // Message and color for status updates
  const [setStatusMessage] = useState("");
  const [setMessageColor] = useState("");

  // Ref to target the form for EmailJS
  const formRef = useRef();

  // Basic email validation
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Handle input changes and set validity
  const handleChange = (e) => {
    const { name, value } = e.target;
    let isValid = value.trim() !== ""; // generic check for non-empty

    if (name === "email") {
      isValid = validateEmail(value);
    }

    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    setFormValidity((prev) => ({
      ...prev,
      [name]: isValid,
    }));
  };

  // Check if all fields pass validation
  const isFormValid = Object.values(formValidity).every(Boolean);

  const [showPopup, setShowPopup] = useState(false);

  const showPopupOnClick = () => {
    setShowPopup(true);
    setTimeout(() => {
      setShowPopup(false);
    }, 15000);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isFormValid) {
      setStatusMessage("Please fill out all fields correctly.");
      setMessageColor("red");
      return;
    }

    emailjs
      .sendForm(
        "service_mhi9zc7", // <-- replace with your service ID
        "template_tpir8xs", // <-- replace with your template ID
        formRef.current,
        "cn2kUwWgECEcM1Zhe" // <-- replace with your public key
      )
      .then(
        () => {
          // Reset form data and validity
          setFormData({ name: "", email: "", subject: "", message: "" });
          setFormValidity({
            name: false,
            email: false,
            subject: false,
            message: false,
          });

          setStatusMessage("Děkuji, brzy se Vám ozvu.");
          setMessageColor("white");

          showPopupOnClick();

          // Clear status message after a few seconds
          setTimeout(() => {
            setStatusMessage("");
          }, 5500);
        },
        (error) => {
          console.error("Failed to send the form:", error.text);
          setStatusMessage("Formulář se nepodařilo odeslat.");
          setMessageColor("red");
        }
      );
  };

  return (
    <form ref={formRef} className="contact-form" onSubmit={handleSubmit}>
    <div class="Formtxt">{t("ContactFormTitle")}</div>
        <input
          type="text"
          name="name"
          placeholder={t("FooterFormName")}
          value={formData.name}
          onChange={handleChange}
          required />
        <input
          type="email"
          name="email"
          placeholder={t("FooterFormEmail")}
          value={formData.email}
          onChange={handleChange}
          required />
        <input
          type="text"
          name="subject"
          placeholder={t("FooterFormTopic")}
          value={formData.subject}
          onChange={handleChange}
          required />
        <textarea
          name="message"
          placeholder={t("FooterFormMessage")}
          value={formData.message}
          onChange={handleChange}
          required />
        {/*
      If you want to style the button differently when disabled,
      you can rely on your CSS or inline styles. For now, we'll keep it simple.
    */}
        <button
          type="submit"
          disabled={!isFormValid}
          style={{ background: isFormValid ? "var(--orange)" : "grey" }}
        >
          {t("FooterFormButton")}
        </button>

        {showPopup && (
          <div className="popup">
            {t("EmailResponse")}
          </div>
        )}
      </form>
  );
}
