import SlideShow from "../components/ui/slideshow/slideshow";
import Counter from "../components/counter";
import SwiperImages from "../components/ui/Swiper/SwiperImages";
import SwiperProductCards from "../components/ui/Swiper/SwiperProductCards";
import SpotLightCards from "../components/ui/spotlightCard/SpotLightCards";
import { useNavigate } from "react-router-dom";
import galleryHomeImage from "../images/homeHeroSection/galleryHomeImage.webp";
import HelmetComponent from "../components/HelmetComponent";
import Spotlight from "../components/ui/SpotLight/Spotlight";
import ParticlesComponent from "../components/ui/particles/Particles";
import CompareDemo from "../components/ui/compare/compareDemo";
import WhyUs from "../components/whyUs/whyUs";

import "../components/ui/button.css";
import balloon from "../images/products/balloon.webp";
import cloud from "../images/products/cloud.webp";
import elipsy from "../images/products/elipsy_stative.webp";
import flat from "../images/products/IMG_20200930_102505.webp";
import cube from "../images/products/led-cube.webp";
import tubs from "../images/products/LED-tubs.webp";
import noGravity from "../images/products/no-gravity.webp";
import suncut from "../images/products/suncut.webp";
import under from "../images/products/podvodni.webp";

import civilWar from "../images/projects/civilwar.webp";
import spiderman from "../images/projects/film1.webp";
import volavka from "../images/projects/volavka.webp";
import terminator from "../images/projects/terminator.webp";
import spectre from "../images/projects/spectre.webp";
import inglorious from "../images/projects/inglorious.webp";
import maleficient from "../images/projects/maleficient.webp";

import { useTranslation } from "react-i18next";
import React from "react";

export default function Home() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.documentElement.scrollHeight,
      behavior: "smooth",
    });
  };

  const scrollToAbout = () => {
    window.scrollTo({
      top: document.getElementById("aboutus-section").offsetTop,
      behavior: "smooth",
    });
  };

  const products = [
    balloon,
    cloud,
    elipsy,
    flat,
    cube,
    tubs,
    noGravity,
    suncut,
    under,
  ];
  const projects = [
    civilWar,
    spiderman,
    terminator,
    spectre,
    maleficient,
    volavka,
    inglorious,
  ];

  const goToProducts = () => {
    navigate("/products");
  };

  const goToProjects = () => {
    navigate("/portfolio");
  };

  const goToGallery = () => {
    navigate("/gallery");
  };

  return (
    <>
      <HelmetComponent
        title={
          "Balloon - Light - Praha s.r.o. | Heliové osvětlovací balony a filmové osvětlení"
        }
        description={
          "Specialisté na heliové osvětlovací balony a profesionální filmové osvětlení. Objevte naše inovativní světelné řešení pro akce v Česku i zahraničí."
        }
        keywords={
          "Balloon - Light, Praha, heliové osvětlovací balony, filmové osvětlení, osvětlovací balony, heliové balony, světelná technika, pronájem, prodej"
        }
      />
      <div className="fixed top-0 right-0 m-5 z-50"></div>

      <section className="herosection">
        <div className="hero-overlay"></div>
        <Spotlight />
        <div className="herosection-left">
          <div className="particles-container">
            <ParticlesComponent />
          </div>

          <div className="hero-content">
            <h1 className="herosection-heading">{t("HomeHero")}</h1>
            <p className="herosection-subtext xl:w-[75%] text-sm md:text-sm lg:text-base xl:text-lg mt-[2rem]">
              {t("HomeHeroDes1")}{" "}
              <span className="text-[var(--orange)]">{t("HomeHeroDes2")} </span>
              {t("HomeHeroDes3")}
            </p>
            <div className="herosection-buttons">
              <button
                onClick={scrollToBottom}
                href="#plan-demo"
                className="herosection-button primary-button"
              >
                {t("HomeHeroButton")}
              </button>
              <button
                onClick={scrollToAbout}
                href="#how-it-works"
                className="herosection-button secondary-button"
              >
                {t("HomeHeroButton2")}
              </button>
            </div>
          </div>
        </div>

        <div className="herosection-right">
          <CompareDemo />
        </div>
        
      </section>
      <div className="hero-slideshow">
          <SlideShow />
        </div> 
      <section className="aboutus-section" id="aboutus-section">
      
        <div className="aboutus-top">
        
          <div className="aboutus-description">
        
            <span className="text-[var(--orange)]">{t("AboutUsDes1")} </span>
            {t("AboutUsDes2")}
          </div>
        </div>
        <div className="aboutus-stats">
          <div className="stat-item">
            <Counter number={3500} className="stat-number" />
            <p className="stat-text">{t("AboutUsProjects")}</p>
          </div>
          <div className="stat-item">
            <Counter number={26} className="stat-number" />
            <p className="stat-text">{t("AboutUsYears2")}</p>
          </div>
          <div className="stat-item">
            <Counter number={186} className="stat-number" />
            <p className="stat-text">{t("AboutUsProducts")}</p>
          </div>
          <div className="stat-item">
            <Counter number={2700} className="stat-number" />
            <p className="stat-text">{t("AboutUsLocations")}</p>
          </div>
        </div>
      </section>
      
      <section className="whyUs-component">
        <WhyUs />
       
      </section>

      <div className="mx-[10%] flex flex-col gap-24">
        <div>
          <div className="flex xl:justify-between justify-between items-center gap-3">
            <h1 className="cards-h1 xl:text-4xl  text-4xl font-bold mb-1">
              {t("HomeProducts")}
            </h1>
            <button
              onClick={goToProducts}
              className=" cards-button relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium rounded-3xl group bg-transparent border-2 border-orange-900 hover-border-color-change"
            >
              <span className="relative px-5 py-2.5 transition-all ease-in duration-75 rounded-3xl text-white">
                {t("HomeProductsButton")}
              </span>
            </button>
          </div>
          <SwiperProductCards slides={products} />
        </div>

        <div>
          <div className="flex xl:justify-between justify-between items-center gap-3">
            <h1 className="xl:text-4xl text-4xl font-bold">
              {t("HomeProjects")}
            </h1>
            <button
              onClick={goToProjects}
              className=" cards-button relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium rounded-3xl group bg-transparent border-2 border-orange-900 hover-border-color-change"
            >
              <span className="relative px-5 py-2.5 transition-all ease-in duration-75 rounded-3xl text-white">
                {t("HomeProjectsButton")}
              </span>
            </button>
          </div>
          <SwiperImages slides={projects} />
        </div>

        <div className="projectsWrapper">
          <div className="flex justify-between items-center gap-3">
            <h1 className="text-4xl font-bold">{t("HomeGallery")}</h1>
            <button
              onClick={goToGallery}
              className="cards-button relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium rounded-3xl group bg-transparent border-2 border-orange-900 hover-border-color-change"
            >
              <span className="relative px-5 py-2.5 transition-all ease-in duration-75 rounded-3xl text-white">
                {t("HomeGalleryButton")}
              </span>
            </button>
          </div>
          <div className="homeGalleryWrapper">
            <img
              className="homeGallery"
              src={galleryHomeImage}
              alt="Gallery Home"
            />
          </div>
        </div>
      </div>
    </>
  );
}
