import React, { useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "./SwiperStyles.css";


const SwiperImages = ({ slides }) => {
  // const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {};

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Swiper
      spaceBetween={50}
      loop={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: true,
      }}
      pagination={{
        clickable: true,
      }}
      breakpoints={{
        1440: { slidesPerView: 4 },
        1024: { slidesPerView: 3 },
        768: { slidesPerView: 2 },
      }}
      modules={[Autoplay, Pagination, Navigation]}
      className="projectsSwiper"
    >
      {slides.map((imageSrc, index) => (
        <SwiperSlide key={index} className="mt-3">
          <img
            src={imageSrc}
            className="foto-graf"
            alt={`Slide ${index + 1}`}
            style={{
              width: "auto",
              height: "auto",
              objectFit: "fill",
              borderRadius: "10px",
            }}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default SwiperImages;
