import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { products } from "../../components/productList.js";
import hoverIcon from "../../images/svg/magnifying-glass.svg";
import { Tab } from "@headlessui/react";
import "../productDetail/productsDetail.css";
import classNames from "classnames";
import HelmetComponent from "../../components/HelmetComponent.jsx";
import SwiperProductCards from "../../components/ui/Swiper/SwiperProductCards";
import { useTranslation } from "react-i18next";

export default function ProductDetail() {
  const { slug } = useParams();
  const product = products.find((p) => p.slug.toString() === slug);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState("");
  const navigate = useNavigate();
  const { t } = useTranslation();

  const goToProducts = () => {
    navigate("/products");
  };

  const openModal = (imageSrc) => {
    setCurrentImage(imageSrc);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  function capitalizeFirstLetters(string) {
    return string
      .toLowerCase()
      .replace(/\b\w/g, (char) => char.toUpperCase());
  }

  // Pokud produkt nebyl nalezen, zobrazíme chybovou hlášku
  if (!product) {
    return <p>Product not found</p>;
  }

  return (
    <>
      <HelmetComponent
        title={capitalizeFirstLetters(
          t(`products.${product.translationKey}.name`)
        )}
        description={t(`products.${product.translationKey}.description`)}
        keywords={`${capitalizeFirstLetters(
          t(`products.${product.translationKey}.name`)
        )}, osvětlení, druhy osvětlení, heliové balony, LED světla, speciální osvětlení, filmové projekty`}
      />
      <div className="min-h-screen p-5 mt-24 mx-[4%]">
        {/* Nadpis */}
        <div className="flex flex-col md:flex-row items-center justify-between mb-5">
          <div className="flex flex-col items-center md:items-start">
            <h1 className="text-5xl font-semibold">
              {t(`products.${product.translationKey}.name`)}
            </h1>
          </div>
        </div>

        {/* Popis a kategorie */}
        <div
          className="flex flex-col md:flex-column border p-4 rounded-lg shadow-lg bg-[#101a2e]"
          style={{ border: "1px solid #1a294a" }}
        >
          <div className="flex-1 ml-0 md:ml-4 mt-4 md:mt-0 mb-3 font-semibold">
            <div className="text-lg tracking-wide">
              {t(`products.${product.translationKey}.description`)}
            </div>
          </div>
          <div className="w-full max-w-full lg:max-w-[100%]">
            <Tab.Group>
              <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
                {Object.keys(product.categories).map((category) => (
                  <Tab
                    key={category}
                    className={({ selected }) =>
                      classNames(
                        "tab-category w-full rounded-lg py-2.5 text-sm font-medium leading-5 ring-offset-blue-400 focus:outline-none focus:ring-2 hover:bg-blue-600",
                        selected ? "bg-blue-600" : ""
                      )
                    }
                  >
                    {t(category)}
                  </Tab>
                ))}
              </Tab.List>
              <Tab.Panels className="mt-2 text-black">
                {Object.entries(product.categories).map(
                  ([category, content], idx) => (
                    <Tab.Panel
                      key={idx}
                      className="rounded-xl bg-white p-3 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2"
                    >
                      <div className="overflow-x-auto">
                        {Array.isArray(content) &&
                        typeof content[0] === "string" ? (
                          <table className="min-w-full text-left border-collapse">
                            <tbody>
                              {content.map((value, index) => (
                                <tr
                                  key={index}
                                  className={
                                    index % 2 === 0 ? "bg-white" : "bg-gray-100"
                                  }
                                >
                                  <td className="py-2 px-4 border-b">
                                    {t(
                                      `products.${product.translationKey}.categories.${category}.${index}`
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          <table className="min-w-full text-left border-collapse">
                            <thead>
                              <tr className="bg-gray-200">
                                {content.length > 0 &&
                                  Object.keys(content[0]).map(
                                    (key, headerIndex) => (
                                      <th
                                        key={headerIndex}
                                        className="py-2 px-4 border-b capitalize"
                                      >
                                        {key}
                                      </th>
                                    )
                                  )}
                              </tr>
                            </thead>
                            <tbody>
                              {content.map((row, rowIndex) => (
                                <tr
                                  key={rowIndex}
                                  className={
                                    rowIndex % 2 === 0
                                      ? "bg-white"
                                      : "bg-gray-100"
                                  }
                                >
                                  {Object.entries(row).map(
                                    ([key, value], cellIndex) => (
                                      <td
                                        key={cellIndex}
                                        className="py-2 px-4 border-b"
                                      >
                                        {value}
                                      </td>
                                    )
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </Tab.Panel>
                  )
                )}
              </Tab.Panels>
            </Tab.Group>
          </div>
        </div>

        {/* Galerie obrázků */}
        <div className="rounded-lg my-5 overflow-hidden">
          <div
            className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 bg-[#101a2e] p-4"
            style={{ border: "1px solid #1a294a" }}
          >
            {product.detailImages.map((image, index) => (
              <div
                key={index}
                className="w-full aspect-w-1 aspect-h-1 overflow-hidden rounded-md cursor-pointer relative group"
                onClick={() => openModal(image)}
              >
                <img
                  src={image}
                  alt={`Detail ${index}`}
                  className="w-full h-full object-cover transform transition-transform duration-300 group-hover:scale-110 group-hover:brightness-50"
                />
                <div className="hover-icon absolute inset-0 flex justify-center items-center opacity-0 group-hover:opacity-100 transition-opacity duration-200 ease-in-out">
                  <img src={hoverIcon} alt="Zvětšit" className="w-10 h-10" />
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Odkaz na další produkty + Swiper */}
        <div>
          <div className="flex xl:justify-between justify-between items-center gap-3 mt-[5%]">
            <h1 className="xl:text-4xl text-2xl font-bold">
              {t("HomeProducts")}
            </h1>
            <button
              onClick={goToProducts}
              className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium rounded-3xl group bg-transparent border-2 border-orange-900 hover-border-color-change"
            >
              <span className="relative px-5 py-2.5 transition-all ease-in duration-75 rounded-3xl text-white">
                {t("ProductsProductsButton")}
              </span>
            </button>
          </div>
          <SwiperProductCards currentProductId={slug} />
        </div>

        {/* Modal pro zvětšení obrázku */}
        {isModalOpen && (
          <div className="modal" onClick={closeModal}>
            <div className="modal-content" onClick={(e) => e.stopPropagation()}>
              <img
                src={currentImage}
                alt="Full size"
                className="modal-image"
                style={{
                  maxWidth: "90%",
                  maxHeight: "90vh",
                  objectFit: "contain",
                }}
              />
            </div>
            <div className="modal-close" onClick={closeModal}>
              &times;
            </div>
            <div className="modal-caption">{t("GalleryModal")}</div>
          </div>
        )}
      </div>
    </>
  );
}
