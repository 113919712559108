import "./App.css";
import { Route, Routes } from "react-router-dom";

import Navbar from "./components/ui/navbar/navbar.jsx";
import Home from "./pages/home.jsx";
import Projects from "./pages/projects-page.jsx";
import Products from "./pages/products.jsx";
import ProductDetail from "./pages/productDetail/product-detail.jsx";
import Video from "./pages/video.jsx";

import {Gallery} from "./pages/gallery.jsx";
import Footer from "./components/ui/footer/footer.jsx";
import ScrollToTop from "./components/scrollToTop.jsx";

import "./i18n.js";

function App() {
  return (
    <div className="App">
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="*" element={<Home />} />
        <Route path="/" element={<Home />} />
        <Route path="/portfolio" element={<Projects />} />
        <Route path="/gallery" element={<Gallery />} />
        <Route path="/products" element={<Products />} />
        <Route path="/product/:slug" element={<ProductDetail />} />
        <Route path="/video" element={<Video />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
