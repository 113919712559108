export default function Video() {
  return (
    <div className="videoWrapper" style={{ width: "48%", margin: "auto"}}>
      <iframe
        width="100%"
        height="515"
        src="https://www.youtube.com/embed/fpPQa4LZmGY?si=Yz-O0GymtN18haoX"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
        style = {{borderRadius: "1rem", marginTop: "4rem"}}
      ></iframe>
    </div>
  );
}


