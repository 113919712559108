// SwiperProductCards.jsx
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import { Link } from "react-router-dom";
import { products } from "../../productList.js";
import { useTranslation } from "react-i18next";
import "./SwiperStyles.css";

const SwiperProductCards = ({ currentProductId }) => {
  const { t } = useTranslation();


  // Filtrování produktů, aby se vyloučil aktuální produkt
  const filteredProducts = products.filter(
    (product) => product.id.toString() !== currentProductId
  );

  return (
    <div className="swiper-container" style={{ marginBottom: "20px" }}>
      <Swiper
        spaceBetween={30}
        loop={true}
        navigation={true}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          1600: {slidesPerView: 4},
          1440: { slidesPerView: 3 },
          768: { slidesPerView: 2 },
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="h-full"
      >
        {filteredProducts.map((product) => (
          <SwiperSlide key={product.id} className="md:w-80">
            <div className="flex flex-grow mb-4 cardbox" style={{ height: "400px" }}>
              <div className="flex justify-between flex-col h-full w-full border p-4 rounded-lg shadow-lg text-white border-none bg-[#101a2e]">
                <div className="flex-shrink-0">
                  <img
                    src={product.image}
                    alt={t(`products.${product.translationKey}.name`)}
                    className="w-full object-cover rounded-md mb-4"
                    style={{ height: "200px" }}
                  />
                </div>
                <div className="flex-1 flex flex-col mb-2">
                  <h1 className="font-semibold text-2xl">
                    {t(`products.${product.translationKey}.name`)}
                  </h1>
                  <p className="flex-1 tracking-wide text-gray-400 md:text-base">
                    {t(`products.${product.translationKey}.miniDescription`)}
                  </p>
                </div>
                <Link to={`/product/${product.slug}`}>
                  <button className="bg-blue-500 font-semibold text-lg tracking-wide px-4 py-2 mt-2 rounded-md w-full">
                    {t("ProductCardButton")}
                  </button>
                </Link>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <style>
        {`
          .swiper-pagination {
            position: relative;
            margin-top: 20px;
          }
          .swiper-container .swiper-slide {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .swiper-slide {
            width: 450px;
            color: orange;
          }
        `}
      </style>
    </div>
  );
};

export default SwiperProductCards;
