import React from "react";
import "./slideshow.css";
import { useEffect } from "react";

function importAll(r) {
  return r.keys().map(r);
}
const images = importAll(require.context('../../../images/brands', false, /\.webp$/));


const Slideshow = () => {
  useEffect(() => {
    const scrollers = document.querySelectorAll(".scroller");

    if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
      addAnimation();
    }

    function addAnimation() {
      scrollers.forEach((scroller) => {
        scroller.setAttribute("data-animated", true);

        const scrollerInner = scroller.querySelector(".scroller__inner");
        const scrollerContent = Array.from(scrollerInner.children);

        scrollerContent.forEach((item) => {
          const duplicatedItem = item.cloneNode(true);
          duplicatedItem.setAttribute("aria-hidden", true);
          scrollerInner.appendChild(duplicatedItem);
        });
      });
    }
  }, []);

  return (
    <div className="scroller" data-speed="fast">
      <ul className="tag-list scroller__inner">
        {images.map((src, index) => (
          <li key={index} className="tag-list__item">
            <img src={src} alt={`Obrázek ${index + 1}`} />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Slideshow;
