import "../components/styles/projectsstyles.css";
import { useLocation } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import LazyLoad from "react-lazyload";

import {
  motion,
  useScroll,
  useSpring,
  useTransform,
  useMotionValue,
  useVelocity,
  useAnimationFrame,
} from "framer-motion";
import { wrap } from "@motionone/utils";
import PropTypes from "prop-types";
import projectsbg from "../images/projects/projectsbg.webp";
import adventFilm from "../images/projects/advent.webp";
import carnivalFilm from "../images/projects/carnival-1.webp";
import child44Film from "../images/projects/child44.webp";
import christopherFilm from "../images/projects/christopher.webp";
import crawlFilm from "../images/projects/crawl.webp";
import CivilwarFilm from "../images/projects/civilwar.webp";
import doctorFilm from "../images/projects/doctor.webp";
import terminatorFilm from "../images/projects/terminator.webp";
import film1 from "../images/projects/film1.webp";
import witcherFilm from "../images/projects/witcher.webp";
import geminimanFilm from "../images/projects/geminiman.webp";
import harrypotterFilm from "../images/projects/Harry-Potter.webp";
import martanFilm from "../images/projects/martan.webp";
import papillonFilm from "../images/projects/papillon.webp";
import robinhoodFilm from "../images/projects/robinhood.webp";
import unlockedFilm from "../images/projects/unlocked.webp";
import volavkaFilm from "../images/projects/volavka.webp";
import zlodejkaFilm from "../images/projects/Zlodejka_knih.webp";
import maleficientFilm from "../images/projects/maleficient.webp";
import aftermathFilm from "../images/projects/aftermath.webp";
import agora from "../images/projects/agora.webp";
import allmoney from "../images/projects/allmoney.webp";
import anonymous from "../images/projects/anonymous.webp";
import anthropoid from "../images/projects/anthropoid.webp";
import benhur from "../images/projects/benhur.webp";
import borgias from "../images/projects/borgias.webp";
import budapest from "../images/projects/budapesthotel.webp";
import certibrko from "../images/projects/certibrko.webp";
import cloudatlas from "../images/projects/cloudatlas.webp";
import emperor from "../images/projects/emperor.webp";
import extinction from "../images/projects/extinction.webp";
import genius from "../images/projects/genius.webp";
import ghost from "../images/projects/ghost.webp";
import ghostprotocol from "../images/projects/ghostprotocol.webp";
import hansel from "../images/projects/hansel.webp";
import hitman from "../images/projects/hitman.webp";
import hostel from "../images/projects/hostel.webp";
import hurricane from "../images/projects/hurricane.webp";
import inglorious from "../images/projects/inglorious.webp";
import janhus from "../images/projects/janhus.webp";
import knightfall from "../images/projects/knightfall.webp";
import lavien from "../images/projects/lavien.webp";
import lines from "../images/projects/lines.webp";
import marcopolo from "../images/projects/marcopolo.webp";
import maria from "../images/projects/maria.webp";
import marypoppins from "../images/projects/marypoppins.webp";
import narnia from "../images/projects/narnia.webp";
import papillon from "../images/projects/papillon.webp";
import pointbreak from "../images/projects/pointbreak.webp";
import shadow from "../images/projects/shadow.webp";
import sherlock from "../images/projects/sherlock.webp";
import snow from "../images/projects/snow.webp";
import spectral from "../images/projects/spectral.webp";
import spectre from "../images/projects/spectre.webp";
import valkyrie from "../images/projects/valkyrie.webp";
import vanhelsing from "../images/projects/vanhelsing.webp";
import wanted from "../images/projects/wanted.webp";
import warhorse from "../images/projects/warhorse.webp";
import xxx from "../images/projects/xxx.webp";
import zoolander from "../images/projects/zoolander.webp";

import HelmetComponent from "../components/HelmetComponent";

import { useTranslation } from "react-i18next";

function ParallaxText({ children, baseVelocity = 100 }) {
  const baseX = useMotionValue(0);
  const { scrollY } = useScroll();
  const scrollVelocity = useVelocity(scrollY);
  const smoothVelocity = useSpring(scrollVelocity, {
    damping: 50,
    stiffness: 400,
  });
  const velocityFactor = useTransform(smoothVelocity, [0, 1000], [0, 5], {
    clamp: false,
  });

  ParallaxText.propTypes = {
    children: PropTypes.string.isRequired,
    baseVelocity: PropTypes.number,
  };

  const x = useTransform(baseX, (v) => `${wrap(-20, -45, v)}%`);
  const directionFactor = useRef(1);

  useAnimationFrame((t, delta) => {
    let moveBy = directionFactor.current * baseVelocity * (delta / 1000);
    if (velocityFactor.get() < 0) {
      directionFactor.current = -1;
    } else if (velocityFactor.get() > 0) {
      directionFactor.current = 1;
    }
    moveBy += directionFactor.current * moveBy * velocityFactor.get();
    baseX.set(baseX.get() + moveBy);
  });

  return (
    <div className="parallax">
      <motion.div className="project-scroller" style={{ x }}>
        <span className="scroller-child">{children} </span>
        <span className="scroller-child">{children} </span>
        <span className="scroller-child">{children} </span>
        <span className="scroller-child">{children} </span>
      </motion.div>
    </div>
  );
}

export default function Projects() {
  const { t } = useTranslation();
  const myRef = useRef(null);
  const handleClick = () => {
    myRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const location = useLocation();

  useEffect(() => {
    if (location.state?.scrollToAbout) {
      const aboutSection = document.getElementById("about-section");
      if (aboutSection) {
        aboutSection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);

  useEffect(() => {
    const handleMouseMove = (e) => {
      setMousePosition({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const images = [
    CivilwarFilm,
    carnivalFilm,
    child44Film,
    christopherFilm,
    crawlFilm,
    adventFilm,
    doctorFilm,
    film1,
    witcherFilm,
    geminimanFilm,
    terminatorFilm,
    harrypotterFilm,
    martanFilm,
    papillonFilm,
    robinhoodFilm,
    unlockedFilm,
    volavkaFilm,
    zlodejkaFilm,
    maleficientFilm,
    aftermathFilm,
    agora,
    allmoney,
    anonymous,
    anthropoid,
    benhur,
    borgias,
    budapest,
    certibrko,
    spectral,
    spectre,
    cloudatlas,
    emperor,
    extinction,
    genius,
    ghost,
    ghostprotocol,
    hansel,
    hitman,
    hostel,
    hurricane,
    inglorious,
    janhus,
    knightfall,
    lavien,
    lines,
    marcopolo,
    maria,
    marypoppins,
    narnia,
    papillon,
    pointbreak,
    shadow,
    sherlock,
    snow,
    valkyrie,
    vanhelsing,
    wanted,
    warhorse,
    xxx,
    zoolander,
  ];

  return (
    <>
      <HelmetComponent
        title={"Projekty | Balloon - Light - Praha s.r.o."}
        description={
          "Objevte naše realizované projekty v Praze, Berlíně, Londýně, Dubaji a dalších městech. Od filmových produkcí až po velkolepé firemní akce."
        }
        keywords={
          "projekty, realizované projekty, filmové osvětlení, filmové kousky, Praha, Berlín, Londýn, Dubaj, Balloon - Light, heliové balony"
        }
      />
      <div className="text-white mx-[10%] mt-[5%] flex-1 sectionproject">
        <div className="heroSection">
          <div className="absolute inset-0 bg-dots z-0">
            <DotsBackground mousePosition={mousePosition} />
          </div>
          <div className="projects-hero ">
            <div className="hero-left h-[85%] flex items-center">
              <div className="project-left-text">
                <h1 className="hero-h1">
                  {t("ProjectsHero")}{" "}
                  <span className="orange-text">{t("ProjectsHero2")}</span>
                </h1>
                <div className="hero-right-text">
                  <p className="hero-text mt-[5%]">{t("ProjectsHeroSub")}</p>
                </div>
                <button
                  onClick={handleClick}
                  className="relative inline-flex items-center mt-[5%] justify-center p-0.5 mb-2 mr-2 overflow-hidden text-lg xl:text-sm font-medium rounded-3xl group bg-transparent border-2 border-orange-900 hover-border-color-change"
                >
                  <span className="projectHeroButton relative px-5 py-2.5 text-3xl transition-all ease-in duration-75 rounded-3xl text-white">
                    {t("ProjectsHeroButton")}
                  </span>
                </button>
              </div>
            </div>
            <div className="hero-right">
              <img className="hero-img" src={projectsbg} alt="" />
            </div>
          </div>
        </div>
      </div>
      <section className="section-scroller">
        <ParallaxText baseVelocity={-1}>
          Praha <span className="orange"> /</span> Munchen{" "}
          <span className="orange"> /</span> Berlin{" "}
          <span className="orange"> /</span> Budapest{" "}
          <span className="orange"> /</span> London{" "}
          <span className="orange"> /</span> Roma{" "}
          <span className="orange"> /</span> Valencia{" "}
          <span className="orange"> /</span>{" "}
        </ParallaxText>
        <ParallaxText baseVelocity={1}>
          Dubai <span className="orange"> /</span> Helsinki{" "}
          <span className="orange"> /</span> Kyjev{" "}
          <span className="orange"> /</span> Bratislava{" "}
          <span className="orange"> /</span> Laverton NV-Australia{" "}
          <span className="orange"> /</span> Paris{" "}
          <span className="orange"> /</span>
        </ParallaxText>
      </section>
      <section>
        <div className="image-grid-container" ref={myRef}>
          {images.map((src, index) => (
            <LazyLoad
              key={index}
              height={200}
              offset={500}
              once
              placeholder={
                <div
                  style={{ height: 200, backgroundColor: "#333" }}
                  className="image-grid-item"
                />
              }
            >
              <img
                src={src}
                alt={`Obrázek ${index + 1}`}
                className="image-grid-item"
              />
            </LazyLoad>
          ))}
        </div>
      </section>
    </>
  );
}

function DotsBackground({ mousePosition }) {
  const [dots, setDots] = useState([]);

  useEffect(() => {
    const rows = Math.ceil(window.innerHeight / 40);
    const cols = Math.ceil(window.innerWidth / 40);
    const newDots = [];

    for (let i = 0; i < rows; i++) {
      for (let j = 0; j < cols; j++) {
        newDots.push({ x: j * 50, y: i * 50 });
      }
    }

    setDots(newDots);
  }, []);

  return (
    <div className="bg-dots">
      {dots.map((dot, index) => (
        <div
          key={index}
          className="dot"
          style={{
            left: dot.x,
            top: dot.y,
            backgroundColor:
              Math.sqrt((mousePosition.x - dot.x) ** 2 + (mousePosition.y - dot.y) ** 2) < 100
                ? "orange"
                : "#555",
          }}
        />
      ))}
    </div>
  );
}
